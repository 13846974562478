import { colors } from '@serenis-health/tokens'
import styled, { css } from 'styled-components'
import { shouldForwardProp } from '../../../utils/shouldForwardProp'
import { Flex } from '../../Flex'
import { type SwitchProps } from '../types'
import { getBackgroundColor } from '../utils/getBackgroundColor'

type Props = Required<Pick<SwitchProps, 'checked' | 'disabled' | 'invalid' | 'name'>>

const transition = css`
  transition: 0.2s;
`

/** @deprecated */
export const StyledLabel = styled(Flex)
  .withConfig({
    displayName: 'Label',
    shouldForwardProp,
  })
  .attrs<Props>(({ name }) => ({
    as: 'label',
    htmlFor: name,
  }))<Props>`
  background-color: ${getBackgroundColor};
  border-radius: 12px;
  cursor: pointer;
  height: 24px;
  position: relative;
  width: 48px;

  ${transition}

  &&::after {
    background-color: ${colors.white};
    border-radius: 8px;
    content: '';
    height: 16px;
    left: ${({ checked }: Props) => (checked ? 44 : 4)}px;
    position: absolute;
    top: 4px;
    width: 16px;

    ${transition}
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      &&:active::after {
        width: 24px;
      }
    `}
`
