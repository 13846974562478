import { type ColorName, toColorName } from '@serenis-health/tokens'
import styled from 'styled-components'
import { shouldForwardProp } from '../utils/shouldForwardProp'

type Props = {
  colorName: ColorName
}

/** @deprecated */
export const Underline = styled.div.withConfig({
  displayName: 'Underline',
  shouldForwardProp,
})<Props>`
  border-bottom: 1px solid ${({ colorName }) => toColorName(colorName)};
`

Underline.displayName = 'Underline'
