import { colors } from '@serenis-health/tokens'
import { type SwitchProps } from '../types'

type Param = Required<Pick<SwitchProps, 'checked' | 'disabled' | 'invalid'>>

/** @deprecated */
export const getBackgroundColor = ({ checked, disabled, invalid }: Param) => {
  if (invalid) {
    return colors.coral10
  }

  if (checked && disabled) {
    return colors.grey08
  }

  if (disabled) {
    return colors.grey06
  }

  if (checked) {
    return colors.purple08
  }

  return colors.grey04
}
