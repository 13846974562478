import { addMonths, startOfDay, startOfMonth, subMonths } from 'date-fns/fp'
import { type FormatDateLanguage, nowInMilliseconds } from 'dates'
import { pipe } from 'fp-ts/function'
import { noop } from 'functions'
import { useCallback, useMemo, useState } from 'react'
import { CalendarProvider } from './hooks/useCalendar'
import { type Day } from './types'
import { View } from './View'

type Props = {
  disabledBeforeDate: Date
  events?: Date[][]
  initialDate?: Date
  language: FormatDateLanguage
  onChange: (value: Day) => void
  onChangeMonth?: (value: Date) => void
  sundaysEnabled?: boolean
}

/** @deprecated */
export type CalendarDay = Day

/** @deprecated */
export const Calendar = ({
  disabledBeforeDate,
  events = [[]],
  initialDate,
  language,
  onChange,
  onChangeMonth = noop,
  sundaysEnabled = false,
}: Props) => {
  const startingDate = useMemo(() => initialDate || pipe(nowInMilliseconds(), startOfDay), [initialDate])

  const [date, setDate] = useState(startingDate)
  const [selectedDay, setSelectedDay] = useState(startingDate)

  const handleSelect = useCallback(
    (day: Day) => {
      setSelectedDay(day.date)

      onChange(day)
    },
    [onChange],
  )

  const handleGoToPreviousMonth = useCallback(() => {
    const selectedDate = subMonths(1)(date)

    setDate(selectedDate)

    onChangeMonth(pipe(selectedDate, startOfMonth))
  }, [date, onChangeMonth, setDate])

  const handleGoToNextMonth = useCallback(() => {
    const selectedDate = addMonths(1)(date)

    setDate(selectedDate)

    onChangeMonth(pipe(selectedDate, startOfMonth))
  }, [date, onChangeMonth, setDate])

  const value = useMemo(
    () => ({ date, disabledBeforeDate, events, language, selectedDay, sundaysEnabled }),
    [date, disabledBeforeDate, events, language, selectedDay, sundaysEnabled],
  )

  return (
    <CalendarProvider value={value}>
      <View
        language={language}
        onNext={handleGoToNextMonth}
        onPrevious={handleGoToPreviousMonth}
        onSelect={handleSelect}
      />
    </CalendarProvider>
  )
}
