import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisQuestion = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.82 7.86c-.17-.46-.73-1.67-2.47-3.38-1.85-1.81-3.13-2.32-3.53-2.44C13.02 2 12.23 2 11.44 2c-2.94 0-4.96.15-6.21.3-1.03.11-1.8.89-1.92 1.92C3.16 5.57 3 7.85 3 11.38c0 3.52.16 5.8.3 7.15.12 1.03.9 1.8 1.93 1.93.89.1 2.16.2 3.86.26"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M19.82 8.07c-.98.15-2.74.04-4.07-.09a2.19 2.19 0 0 1-1.98-1.97 19.46 19.46 0 0 1-.09-3.98M20.81 16.65s-.22-.99-1.55-2.32c-1.33-1.33-2.32-1.55-2.32-1.55"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M22.06 15.4c.68-.68.94-1.67.37-2.45a8.33 8.33 0 0 0-1.79-1.79c-.77-.57-1.77-.3-2.45.37l-5.78 5.79c-.26.25-.43.57-.46.93-.04.5-.07 1.32 0 2.55a.9.9 0 0 0 .85.84c1.22.08 2.05.05 2.54 0 .36-.02.68-.2.94-.45l5.78-5.79Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
