import { isSameDay } from 'date-fns/fp'
import { nowInMilliseconds } from 'dates'
import { type Day } from '../types'
import { getCurrentMonthDays } from './getCurrentMonthDays'
import { getPreviousMonthDays } from './getPreviousMonthDays'
import { isCalendarDayDisabled } from './isCalendarDayDisabled'

type Param = {
  date: Date
  disabledBeforeDate: Date
  events: Date[][]
  selectedDay: Date
  sundaysEnabled: boolean
}

/** @deprecated */
export const getCalendarDays = ({ date, disabledBeforeDate, events, selectedDay, sundaysEnabled }: Param): Day[] => {
  const today = nowInMilliseconds()

  const previousMonthDays = getPreviousMonthDays(date)

  const currentMonthDays = getCurrentMonthDays(date)

  const isDisabled = isCalendarDayDisabled({ disabledBeforeDate, sundaysEnabled })

  return previousMonthDays.concat(currentMonthDays).map((day) => ({
    ...day,
    disabled: isDisabled(day),
    hasEvent: events.flatMap((dates) => dates.filter((date) => isSameDay(date, day.date))).length,
    selected: isSameDay(day.date, selectedDay),
    today: isSameDay(day.date, today),
  }))
}
