import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisAgendaSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.712 1.264a2.662 2.662 0 0 0-2.455 2.489C2.132 5.443 2 8.142 2 12c0 3.858.132 6.557.257 8.247a2.662 2.662 0 0 0 2.455 2.489V1.264Z"
      fill={color}
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      clipRule="evenodd"
      d="M21.42 20.25a2.664 2.664 0 0 1-2.45 2.49c-1.55.13-3.94.26-7.29.26-1.85 0-2.9-.04-4.18-.1V1.1c1.28-.06 2.34-.1 4.18-.1 3.35 0 5.74.13 7.29.26 1.34.11 2.36 1.15 2.45 2.49.12 1.69.26 4.39.26 8.25 0 3.86-.13 6.56-.26 8.25ZM10.96 5.5c-.81.06-1.272.41-1.39 1.41C9.53 7.25 9.5 8.3 9.5 9s.03 1.46.07 1.8c.082.7.58 1.04 1.39 1.11.66.05 1.64.09 3.03.09s3.71-.04 4.37-.09c.81-.06 1.308-.41 1.39-1.11.04-.34.07-1.1.07-1.8s-.03-1.75-.07-2.09c-.104-.88-.58-1.34-1.39-1.41-.66-.05-2.98-.09-4.37-.09-1.39 0-2.37.04-3.03.09Z"
      fill={color}
      fillRule="evenodd"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
