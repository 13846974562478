import { type ColorName } from '@serenis-health/tokens'
import { type ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { Flex } from '../../Flex'
import { type Day } from '../types'

type Props = {
  day?: Day
  children: ReactNode
}

type Param = {
  day: Props['day']
  hover: boolean
}

const getBackgroundColorName = ({ day, hover }: Param): ColorName => {
  if (!day || !day.belongsToCurrentMonth) {
    return 'white'
  }

  if (day.selected) {
    return 'purple08'
  }

  if (day.today) {
    return 'purple04'
  }

  if (day.disabled) {
    return 'white'
  }

  return hover ? 'purple04' : 'white'
}

const Cell = styled(Flex)`
  height: 32px;
`

const Wrapper = styled(Flex)`
  width: 14.28%;
`

/** @deprecated */
export const CalendarCell = ({ children, day }: Props) => {
  const { background, backgroundHover } = useMemo(
    () => ({
      background: getBackgroundColorName({ day, hover: false }),
      backgroundHover: getBackgroundColorName({ day, hover: true }),
    }),
    [day],
  )

  return (
    <Wrapper pb={8} px={4}>
      <Cell align="center" background={background} backgroundHover={backgroundHover} borderRadius={12} justify="center">
        {children}
      </Cell>
    </Wrapper>
  )
}
