import { type ButtonHTMLAttributes } from 'react'
import styled from 'styled-components'

export type PressableProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  $isLoading?: boolean
}

export const Pressable = styled.button.attrs<PressableProps>(({ $isLoading, disabled, ...props }) => ({
  'aria-busy': $isLoading,
  'aria-disabled': disabled,
  'aria-live': 'polite',
  type: 'button',
  ...props,
}))`
  all: unset;
  outline: revert;
  cursor: pointer;

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
  }

  &[aria-busy='true'] {
    cursor: wait;
  }
`
