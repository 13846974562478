import { toColorName, toColorNameHover } from '@serenis-health/tokens'
import { type TextProps } from '../types/text'

/** @deprecated */
export function getColorByColorName({ colorName }: TextProps) {
  return `color: ${toColorName(colorName) || 'currentColor'};`
}

/** @deprecated */
export function getColorHoverByColorName({ colorName, colorNameHover }: TextProps) {
  return `color: ${colorNameHover ? toColorName(colorNameHover) : toColorNameHover(colorName) || 'currentColor'};`
}
