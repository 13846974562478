import { type ComponentPropsWithoutRef } from 'react'
import { Button } from 'ui'
import { useWindowLocation } from '~/hooks/useWindowLocation'
import { Translation } from './Translation'

type Props = Partial<Pick<ComponentPropsWithoutRef<typeof Button>, 'kind'>>

export const LocationReloadButton = ({ kind = 'primary' }: Props) => {
  const location = useWindowLocation()

  return (
    <Button kind={kind} onClick={location.reload}>
      <Translation id="actions.locationReload" />
    </Button>
  )
}
