import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisSidebarExpand = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.75367 1.28233c-1.33834.10896-2.36238 1.133-2.47134 2.47134C1.1451 5.44452 1 8.14267 1 12c0 3.8573.1451 6.5555.28233 8.2463.10896 1.3384 1.133 2.3624 2.47134 2.4714C5.44452 22.8549 8.14267 23 12 23c3.8573 0 6.5555-.1451 8.2463-.2823 1.3384-.109 2.3624-1.133 2.4714-2.4714C22.8549 18.5555 23 15.8573 23 12c0-3.85733-.1451-6.55548-.2823-8.24633-.109-1.33834-1.133-2.36238-2.4714-2.47134C18.5555 1.1451 15.8573 1 12 1c-3.85733 0-6.55548.1451-8.24633.28233Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="m15.5137 15.5146 3.2571-3.2571-3.2571-3.25714M10 12.2578h8.7714"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path d="M5.7 1v22" stroke={color} strokeWidth="1.4" />
  </svg>
)
