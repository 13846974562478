import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisCalendar = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.586 8.5c.051-1.238.118-2.23.183-2.997.104-1.216 1.082-2.147 2.36-2.246C5.743 3.132 8.318 3 12 3c3.682 0 6.257.132 7.871.257 1.278.099 2.256 1.03 2.36 2.246.065.766.132 1.76.183 2.997m-20.828 0C1.535 9.746 1.5 11.24 1.5 13c0 3.507.138 5.96.27 7.497.103 1.216 1.08 2.148 2.359 2.246C5.743 22.868 8.318 23 12 23c3.682 0 6.257-.132 7.871-.257 1.278-.098 2.256-1.03 2.36-2.246.13-1.538.269-3.99.269-7.497 0-1.76-.035-3.254-.086-4.5m-20.828 0h20.828M7.5 5.503V1m9 4.503V1"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
