import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisMenuSolid = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.08 2.918c.058-.906.647-1.609 1.548-1.732C3.365 1.086 4.448 1 6 1c1.553 0 2.636.086 3.372.186.9.123 1.49.826 1.548 1.732.044.689.08 1.68.08 3.082s-.036 2.393-.08 3.082c-.058.906-.647 1.61-1.548 1.732-.737.1-1.82.186-3.372.186-1.553 0-2.635-.086-3.372-.186-.9-.123-1.49-.826-1.548-1.732C1.036 8.393 1 7.402 1 6s.036-2.393.08-3.082ZM1.08 14.918c.058-.906.647-1.61 1.548-1.732C3.365 13.086 4.448 13 6 13c1.553 0 2.636.086 3.372.186.9.123 1.49.826 1.548 1.732.044.689.08 1.68.08 3.082s-.036 2.393-.08 3.082c-.058.906-.647 1.61-1.548 1.732-.737.1-1.82.186-3.372.186-1.553 0-2.635-.086-3.372-.186-.9-.123-1.49-.826-1.548-1.732C1.036 20.393 1 19.402 1 18s.036-2.393.08-3.082ZM13.08 2.918c.058-.906.647-1.609 1.548-1.732C15.365 1.086 16.448 1 18 1c1.553 0 2.636.086 3.372.186.9.123 1.49.826 1.548 1.732.044.689.08 1.68.08 3.082s-.036 2.393-.08 3.082c-.058.906-.647 1.61-1.548 1.732-.737.1-1.82.186-3.372.186-1.553 0-2.635-.086-3.372-.186-.9-.123-1.49-.826-1.548-1.732C13.036 8.393 13 7.402 13 6s.036-2.393.08-3.082ZM13.08 14.918c.058-.906.647-1.61 1.548-1.732.737-.1 1.82-.186 3.372-.186 1.553 0 2.636.086 3.372.186.9.123 1.49.826 1.548 1.732.044.689.08 1.68.08 3.082s-.036 2.393-.08 3.082c-.058.906-.647 1.61-1.548 1.732-.737.1-1.82.186-3.372.186-1.553 0-2.635-.086-3.372-.186-.9-.123-1.49-.826-1.548-1.732-.044-.689-.08-1.68-.08-3.082s.036-2.393.08-3.082Z"
      fill={color}
    />
  </svg>
)
