import { css, type CSSProperties } from 'styled-components'
import { type ResponsiveProps, type StyledProps } from '../utils/props'
import { toValueOrPX } from '../utils/toValueOrPX'

type BaseSpacingProps = {
  p?: CSSProperties['padding']
  pt?: CSSProperties['paddingTop']
  pr?: CSSProperties['paddingRight']
  pb?: CSSProperties['paddingBottom']
  pl?: CSSProperties['paddingLeft']
  px?: CSSProperties['paddingRight'] & CSSProperties['paddingLeft']
  py?: CSSProperties['paddingTop'] & CSSProperties['paddingBottom']
}

type MdSpacingProps = ResponsiveProps<BaseSpacingProps, 'md'>
type LgSpacingProps = ResponsiveProps<BaseSpacingProps, 'lg'>
type XlSpacingProps = ResponsiveProps<BaseSpacingProps, 'xl'>

export type SpacingProps = StyledProps<BaseSpacingProps & MdSpacingProps & LgSpacingProps & XlSpacingProps>

export const generateSpacingStyles = ({ p, pt, pr, pb, pl, px, py }: BaseSpacingProps) => css`
  padding: ${toValueOrPX(p)};
  padding-top: ${toValueOrPX(pt) ?? toValueOrPX(py)};
  padding-right: ${toValueOrPX(pr) ?? toValueOrPX(px)};
  padding-bottom: ${toValueOrPX(pb) ?? toValueOrPX(py)};
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  padding-left: ${toValueOrPX(pl) ?? toValueOrPX(px)};
`
