import { dateFields } from '~/__generated__/dateFields'

type GraphqlObjectField = { __typename?: string; [key: string]: unknown }

const isObjectField = (value: unknown): value is GraphqlObjectField =>
  typeof value === 'object' && value != null && !Array.isArray(value)

/**
 * Given a graphql response (e.g. `{ data: {}, errors: [] }``), it will cast all the date fields to Date objects.
 */
export const castGraphqlDateFields = <T>(data: T, key?: string, typename?: string): T => {
  if (Array.isArray(data)) {
    return data.map((item) => castGraphqlDateFields(item)) as T
  }

  if (isObjectField(data)) {
    // Ignore graphql error responses
    if (key == null && data.errors != null) {
      return data
    }

    return Object.entries(data).reduce(
      (acc, [key, value]) => {
        acc[key] = castGraphqlDateFields(value, key, data.__typename)

        return acc
      },
      {} as Record<string, unknown>,
    ) as T
  }

  if (typename != null && key != null && dateFields[typename]?.includes(key)) {
    return (data != null ? new Date(data as string | number) : null) as T
  }

  return data
}
