import { type ColorName } from 'design-tokens'

export const iconNames = [
  'alarm',
  'american-express',
  'apple-imac-2021',
  'apple-mac',
  'apple',
  'archive',
  'arrow-down-circle',
  'arrow-down',
  'arrow-left-circle',
  'arrow-left-to-line',
  'arrow-left',
  'arrow-right-circle',
  'arrow-right-to-line',
  'arrow-right',
  'arrow-up-circle',
  'attachment',
  'badge-check',
  'bell-notification',
  'bell',
  'bin-minus-in',
  'book-stack',
  'bookmark-book',
  'bookmark',
  'brain',
  'building',
  'calendar',
  'chat-bubble-empty',
  'chat-bubble-question',
  'chat-bubble-warning',
  'chat-lines',
  'check-circle',
  'check',
  'chevron-left-circle',
  'chevron-right-circle',
  'community',
  'computer',
  'copy',
  'credit-card-slash',
  'credit-card',
  'delete-circle',
  'donate',
  'double-check',
  'download',
  'drag',
  'edit-pencil',
  'edit',
  'expand',
  'eye-closed',
  'eye',
  'facebook',
  'fast-right-circle',
  'finder',
  'folder',
  'gift',
  'google-docs',
  'google',
  'graduation-cap',
  'group',
  'headset-help',
  'home-hospital',
  'home-simple-door',
  'home',
  'hospital',
  'hourglass',
  'infinite',
  'info-circle',
  'instagram',
  'intercom',
  'journal-page',
  'language',
  'laptop',
  'lifebelt',
  'line-space',
  'link',
  'linkedin',
  'list',
  'lock-square',
  'lock',
  'log-out',
  'logo-apple',
  'logo-facebook',
  'logo-google',
  'long-arrow-down-right',
  'maestro',
  'mail',
  'map-pin',
  'mastercard',
  'medal-1st',
  'medal',
  'media-image',
  'menu-scale',
  'menu',
  'message-text',
  'microphone-mute',
  'microphone',
  'minus-circle',
  'minus',
  'more-horiz',
  'more-vert',
  'multi-window',
  'multiple-pages-plus',
  'nav-arrow-down',
  'nav-arrow-left',
  'nav-arrow-right',
  'nav-arrow-up',
  'on-tag',
  'one-finger-select-hand-gesture',
  'open-in-window',
  'page-edit',
  'page-flip',
  'page-plus',
  'page-star',
  'paste-clipboard',
  'path-coaching-solid',
  'path-coaching',
  'path-couples-solid',
  'path-couples',
  'path-myself-solid',
  'path-myself',
  'path-nutrition-dca-solid',
  'path-nutrition-dca',
  'path-nutrition-weight-loss-solid',
  'path-nutrition-weight-loss',
  'path-psychiatry-solid',
  'path-psychiatry',
  'path-sexology-solid',
  'path-sexology',
  'paypal',
  'pc-check',
  'peace-hand',
  'people-tag',
  'percentage-circle',
  'percentage-round',
  'pharmacy-cross-tag',
  'phone',
  'plus',
  'poste-pay',
  'powered-by-stripe',
  'privacy-policy',
  'profile-circle',
  'prohibition',
  'question-mark',
  'question-mark-circle',
  'receive-euros',
  'reduce',
  'refresh-double',
  'repeat',
  'report-columns',
  'reports-excellent',
  'reports-good',
  'reports-poor',
  'reports',
  'restart',
  'search',
  'send-diagonal',
  'send-mail',
  'send',
  'server',
  'settings',
  'share-ios',
  'smartphone-device',
  'sound-high',
  'sound-off',
  'sphere',
  'srns-agenda-solid',
  'srns-agenda',
  'srns-calendar-solid',
  'srns-calendar',
  'srns-chat-solid',
  'srns-chat',
  'srns-clock',
  'srns-community',
  'srns-contact-phonebook',
  'srns-generic',
  'srns-home-solid',
  'srns-home',
  'srns-journaling-solid',
  'srns-journaling',
  'srns-member-get-member',
  'srns-menu-solid',
  'srns-menu',
  'srns-question',
  'srns-patients-solid',
  'srns-patients',
  'srns-ringing-bell',
  'srns-shipping-box',
  'srns-sidebar-collapse',
  'srns-sidebar-expand',
  'srns-sticker',
  'srns-sticker-square',
  'srns-therapy-solid',
  'srns-therapy',
  'star',
  'star-solid',
  'stats-up-square',
  'three-stars',
  'timer-off',
  'timer',
  'transition-left',
  'transition-right',
  'trash',
  'twitter',
  'user-badge-check',
  'user-circle',
  'user-love',
  'user-scan',
  'user-star',
  'user-xmark',
  'user',
  'video-camera-off',
  'video-camera',
  'view-structure-up',
  'visa',
  'wallet',
  'warning-circle-solid',
  'warning-circle',
  'whatsapp',
  'xmark-circle',
  'xmark',
] as const

export type IconName = (typeof iconNames)[number]

export type IconSize = 12 | 16 | 20 | 24 | 32 | 40 | 48 | 56 | 64 | 72 | 80 | 120

export type IconProps = {
  colorName?: ColorName
  fillColorName?: ColorName
  name: IconName
  size: IconSize
}
