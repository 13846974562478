/**
 * THIS IS AN AUTOGENERATED FILE.
 * DO NOT EDIT THIS FILE DIRECTLY.
 */

import { cssvarValue, DESIGN_TOKENS_PREFIX } from '../utils'

/** `0 0 0 0 transparent` */
export const ELEVATION_0 = `var(--${DESIGN_TOKENS_PREFIX}-elevation-0)` as const
export const ELEVATION_0_VALUE = '0 0 0 0 transparent' as const

/** In `light` theme is `inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 8px -2px rgb(from #5D29D8 r g b / 8%), 0 0 2px 0 rgb(from #5D29D8 r g b / 24%), 0 2px 2px -4px rgb(from #5D29D8 r g b / 64%)` */
export const ELEVATION_XXS = `var(--${DESIGN_TOKENS_PREFIX}-elevation-xxs)` as const
export const ELEVATION_XXS_VALUE = cssvarValue('elevation-xxs')

/** In `light` theme is `inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 4px 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 2px 0 rgb(from #5D29D8 r g b / 24%), 0 2px 8px -4px rgb(from #5D29D8 r g b / 32%)` */
export const ELEVATION_XS = `var(--${DESIGN_TOKENS_PREFIX}-elevation-xs)` as const
export const ELEVATION_XS_VALUE = cssvarValue('elevation-xs')

/** In `light` theme is `inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 8px -2px rgb(from #5D29D8 r g b / 24%), 0 12px 8px -12px rgb(from #5D29D8 r g b / 24%)` */
export const ELEVATION_SM = `var(--${DESIGN_TOKENS_PREFIX}-elevation-sm)` as const
export const ELEVATION_SM_VALUE = cssvarValue('elevation-sm')

/** In `light` theme is `inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 16px -4px rgb(from #5D29D8 r g b / 24%), 0 16px 12px -16px rgb(from #5D29D8 r g b / 16%)` */
export const ELEVATION_MD = `var(--${DESIGN_TOKENS_PREFIX}-elevation-md)` as const
export const ELEVATION_MD_VALUE = cssvarValue('elevation-md')

/** In `light` theme is `inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 24px -4px rgb(from #5D29D8 r g b / 16%), 0 16px 24px -16px rgb(from #5D29D8 r g b / 16%)` */
export const ELEVATION_LG = `var(--${DESIGN_TOKENS_PREFIX}-elevation-lg)` as const
export const ELEVATION_LG_VALUE = cssvarValue('elevation-lg')

/** In `light` theme is `inset 0 0.5px 0 0 rgb(from #5D29D8 r g b / 24%), inset 0 -0.5px 0 0 rgb(from #5D29D8 r g b / 32%), 0 0 16px -2px rgb(from #5D29D8 r g b / 8%), 0 0 32px -8px rgb(from #5D29D8 r g b / 16%), 0 32px 32px -24px rgb(from #5D29D8 r g b / 16%)` */
export const ELEVATION_XL = `var(--${DESIGN_TOKENS_PREFIX}-elevation-xl)` as const
export const ELEVATION_XL_VALUE = cssvarValue('elevation-xl')
