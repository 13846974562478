import { type ColorName } from '@serenis-health/tokens'
import { type State } from '../types/useInput'

type Param = {
  focused: boolean
  state: State
}

/** @deprecated */
export const getUnderlineColorName = ({ focused, state }: Param): ColorName => {
  if (state === 'disabled') {
    return 'grey08'
  }

  if (state === 'read-only') {
    return 'grey08'
  }

  if (state === 'error') {
    return 'coral10'
  }

  if (focused) {
    return 'purple08'
  }

  return 'purple06'
}
