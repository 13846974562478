import { css, type Interpolation, type RuleSet } from 'styled-components'

/** @deprecated */
export const cssWithMediaQuery =
  (mediaQuery: string) =>
  (first: TemplateStringsArray, ...interpolations: Interpolation<object>[]): RuleSet<object> => {
    const styles = css(first, ...interpolations)

    if (!styles.join('').trim()) {
      return ['']
    }

    return css`
      /* stylelint-disable-next-line media-query-no-invalid */
      @media ${mediaQuery} {
        ${styles}
      }
    `
  }
