import {
  BORDER_RADIUS_XS,
  COLOR_PRIMARY_40,
  COLOR_PRIMARY_50,
  COLOR_YELLOW_20,
  COLOR_YELLOW_60,
  type ColorName,
  type cssvarValueColor,
  SPACING_XS,
} from 'design-tokens'
import { type AriaAttributes, type PropsWithChildren, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { uidWithPrefix } from 'uid'
import { Card } from './Card'
import { Text } from './Text'
import { shouldForwardProp } from './utils/shouldForwardProp'

type Kind = 'info' | 'warning'

export const suggestionKindsColors: Record<
  Kind,
  {
    background: ColorName
    borderColor: ReturnType<typeof cssvarValueColor>
    rectangleColor: ReturnType<typeof cssvarValueColor>
  }
> = {
  info: { background: 'primary-10', borderColor: COLOR_PRIMARY_40, rectangleColor: COLOR_PRIMARY_50 },
  warning: { background: 'yellow-20', borderColor: COLOR_YELLOW_20, rectangleColor: COLOR_YELLOW_60 },
} as const

type SuggestionContainerProps = PropsWithChildren<{ kind: Kind }>

const SuggestionContainer = styled(Card).withConfig({
  displayName: 'Suggestion',
  shouldForwardProp,
})<SuggestionContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${SPACING_XS};
  ${({ kind }) => css`
    border-color: ${suggestionKindsColors[kind].borderColor};
    border-left-width: 4px;
    border-left-color: ${suggestionKindsColors[kind].rectangleColor};
  `}
  overflow: hidden;
  border-radius: ${BORDER_RADIUS_XS};
`

export type SuggestionProps =
  | {
      children?: never
      description: string
      kind: Kind
      title?: string
    }
  | PropsWithChildren<{
      description?: never
      kind: Kind
      title?: never
    }>

export const Suggestion = ({ children, description, kind, title }: SuggestionProps) => {
  const uuid = useMemo(() => uidWithPrefix('suggestion'), [])

  const ariaAttrs: AriaAttributes = useMemo(
    () => ({
      'aria-describedby': `description_${uuid}`,
      ...(title && { 'aria-labelledby': `title_${uuid}` }),
    }),
    [title, uuid],
  )

  return (
    <SuggestionContainer
      {...(!children && ariaAttrs)}
      as="aside"
      backgroundColorName={suggestionKindsColors[kind].background}
      kind={kind}
      role="note"
      size="xs"
    >
      {children ? (
        <div>{children}</div>
      ) : (
        <>
          {title && (
            <Text as="p" colorName="black" fontWeight="600" id={`title_${uuid}`} kind="caption">
              {title}
            </Text>
          )}
          <Text as="p" colorName="black" id={`description_${uuid}`} kind="caption">
            {description}
          </Text>
        </>
      )}
    </SuggestionContainer>
  )
}
