import { identity, pipe } from 'fp-ts/function'
import * as translations from '~/i18n'
import { type TranslationId, type TranslationValues } from '~/i18n/types'
import { fillTextValues } from '../../utils/fillTextValues'
import { type Language, useLanguage } from './useLanguage'

export const getTranslation = (language: Language) => (id: TranslationId, values?: TranslationValues) =>
  pipe(
    translations[language][id] || translations.it[id],
    values ? (translation) => fillTextValues({ text: translation || id, values }) : identity,
  )

const getTranslationOrDefault = (language: Language) => (id: string | TranslationId, values?: TranslationValues) =>
  pipe(
    translations[language][id as TranslationId] || translations.it[id as TranslationId],
    values ? (translation) => fillTextValues({ text: translation || id, values }) : identity,
  ) || id

export const useTranslation = (id: TranslationId, values?: TranslationValues) => {
  const { language } = useLanguage()

  return getTranslation(language)(id, values)
}

export const useTranslationOrDefault = (id: string | TranslationId, values?: TranslationValues) => {
  const { language } = useLanguage()

  return getTranslationOrDefault(language)(id, values)
}

export const useTranslate = () => {
  const { language } = useLanguage()

  return (id: TranslationId, values?: TranslationValues) => getTranslation(language)(id, values)
}
