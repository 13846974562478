import { type BaseHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'
import { media } from '../media'
import { type BackgroundProps, generateBackgroundStyles } from './backgroundProps'
import { type BorderProps, generateBorderStyles } from './borderProps'
import { type DisplayProps, generateDisplayStyles } from './displayProps'
import { type ElevationProps, generateElevationStyles } from './elevationProps'
import { type FlexboxProps, generateFlexboxStyles } from './flexboxProps'
import { generatePositionStyles, type PositionProps } from './positionProps'
import { generateSizeStyles, type SizeProps } from './sizeProps'
import { generateSpacingStyles, type SpacingProps } from './spacingProps'

export type FlexProps = BaseHTMLAttributes<HTMLDivElement> &
  BackgroundProps &
  BorderProps &
  DisplayProps &
  ElevationProps &
  FlexboxProps &
  PositionProps &
  SizeProps &
  SpacingProps

export const Flex = styled.div.withConfig({ displayName: 'Flex' })<FlexProps>`
  ${({
    $position: position,
    $zIndex: zIndex,
    $top: top,
    $right: right,
    $bottom: bottom,
    $left: left,
    $hide: hide = false,
    $overflow: overflow,
    $opacity: opacity,
    $align: align = 'stretch',
    $basis: basis = 'auto',
    $direction: direction = 'column',
    $gap: gap,
    $grow: grow = 0,
    $justify: justify = 'flex-start',
    $shrink: shrink = 0,
    $wrap: wrap = 'nowrap',
    $minWidth: minWidth = 0,
    $maxWidth: maxWidth,
    $minHeight: minHeight = 0,
    $maxHeight: maxHeight,
    $p: p,
    $pt: pt,
    $pr: pr,
    $pb: pb,
    $pl: pl,
    $px: px,
    $py: py,
    $borderSize: borderSize,
    $borderSizeTop: borderSizeTop,
    $borderSizeRight: borderSizeRight,
    $borderSizeBottom: borderSizeBottom,
    $borderSizeLeft: borderSizeLeft,
    $borderStyle: borderStyle = borderSize ? 'solid' : undefined,
    $borderStyleTop: borderStyleTop = borderSizeTop ? (borderStyle ?? 'solid') : undefined,
    $borderStyleRight: borderStyleRight = borderSizeRight ? (borderStyle ?? 'solid') : undefined,
    $borderStyleBottom: borderStyleBottom = borderSizeBottom ? (borderStyle ?? 'solid') : undefined,
    $borderStyleLeft: borderStyleLeft = borderSizeLeft ? (borderStyle ?? 'solid') : undefined,
    $borderColorName: borderColorName,
    $borderColorNameTop: borderColorNameTop,
    $borderColorNameRight: borderColorNameRight,
    $borderColorNameBottom: borderColorNameBottom,
    $borderColorNameLeft: borderColorNameLeft,
    $borderRadius: borderRadius,
    $borderRadiusTopLeft: borderRadiusTopLeft,
    $borderRadiusTopRight: borderRadiusTopRight,
    $borderRadiusBottomRight: borderRadiusBottomRight,
    $borderRadiusBottomLeft: borderRadiusBottomLeft,
    $backgroundColorName: backgroundColorName,
    $elevationName: elevationName,
  }) => css`
    ${generatePositionStyles({ position, zIndex, top, right, bottom, left })}
    ${generateDisplayStyles({ hide, opacity, overflow })}
    ${generateFlexboxStyles({ align, basis, direction, gap, grow, justify, shrink, wrap })}
    ${generateSizeStyles({ maxHeight, minHeight, maxWidth, minWidth })}
    ${generateSpacingStyles({ p, pt, pr, pb, pl, px, py })}
    ${generateBorderStyles({
      borderSize,
      borderSizeTop,
      borderSizeRight,
      borderSizeBottom,
      borderSizeLeft,
      borderStyle,
      borderStyleTop,
      borderStyleRight,
      borderStyleBottom,
      borderStyleLeft,
      borderColorName,
      borderColorNameTop,
      borderColorNameRight,
      borderColorNameBottom,
      borderColorNameLeft,
      borderRadius,
      borderRadiusTopLeft,
      borderRadiusTopRight,
      borderRadiusBottomRight,
      borderRadiusBottomLeft,
    })}
    ${generateBackgroundStyles({ backgroundColorName })}
    ${generateElevationStyles({ elevationName })}
  `}

  ${({
    $mdHide: hide,
    $mdAlign: align,
    $mdBasis: basis,
    $mdDirection: direction,
    $mdGap: gap,
    $mdGrow: grow,
    $mdJustify: justify,
    $mdShrink: shrink,
    $mdWrap: wrap,
    $mdP: p,
    $mdPt: pt,
    $mdPr: pr,
    $mdPb: pb,
    $mdPl: pl,
    $mdPx: px,
    $mdPy: py,
  }) => media.gtSm`
    ${generateDisplayStyles({ hide })}
    ${generateFlexboxStyles({ align, basis, direction, gap, grow, justify, shrink, wrap })}
    ${generateSpacingStyles({ p, pt, pr, pb, pl, px, py })}
  `}

  ${({
    $lgHide: hide,
    $lgAlign: align,
    $lgBasis: basis,
    $lgDirection: direction,
    $lgGap: gap,
    $lgGrow: grow,
    $lgJustify: justify,
    $lgShrink: shrink,
    $lgWrap: wrap,
    $lgP: p,
    $lgPt: pt,
    $lgPr: pr,
    $lgPb: pb,
    $lgPl: pl,
    $lgPx: px,
    $lgPy: py,
  }) => media.gtMd`
    ${generateDisplayStyles({ hide })}
    ${generateFlexboxStyles({ align, basis, direction, gap, grow, justify, shrink, wrap })}
    ${generateSpacingStyles({ p, pt, pr, pb, pl, px, py })}
  `}

  ${({
    $xlHide: hide,
    $xlAlign: align,
    $xlBasis: basis,
    $xlDirection: direction,
    $xlGap: gap,
    $xlGrow: grow,
    $xlJustify: justify,
    $xlShrink: shrink,
    $xlWrap: wrap,
    $xlP: p,
    $xlPt: pt,
    $xlPr: pr,
    $xlPb: pb,
    $xlPl: pl,
    $xlPx: px,
    $xlPy: py,
  }) => media.gtLg`
    ${generateDisplayStyles({ hide })}
    ${generateFlexboxStyles({ align, basis, direction, gap, grow, justify, shrink, wrap })}
    ${generateSpacingStyles({ p, pt, pr, pb, pl, px, py })}
  `}
`
