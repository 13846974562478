import { Flex } from 'cdk'
import { BORDER_RADIUS_CIRCLE, TIME_150 } from 'design-tokens'
import { memo, type PropsWithChildren, useCallback, useState } from 'react'
import { acronym } from 'strings'
import styled, { css } from 'styled-components'
import { Text, type TextProps } from './Text'

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl'

export const avatarSizesDimensions: Record<
  Size,
  {
    size: number
    fontKind: TextProps['kind']
    fontWeight: TextProps['fontWeight']
  }
> = {
  xs: { size: 24, fontKind: 'footnote', fontWeight: '400' },
  sm: { size: 40, fontKind: 'caption', fontWeight: '400' },
  md: { size: 48, fontKind: 'paragraph', fontWeight: '400' },
  lg: { size: 64, fontKind: 'h2', fontWeight: '400' },
  xl: { size: 80, fontKind: 'h1', fontWeight: '400' },
  '2xl': { size: 96, fontKind: 'h1', fontWeight: '400' },
}

type AvatarContainerProps = PropsWithChildren<{
  $size: Size
}>

const AvatarContainer = styled(Flex).withConfig({ displayName: 'Avatar' })<AvatarContainerProps>`
  ${({ $size }: AvatarContainerProps) => {
    const { size } = avatarSizesDimensions[$size]
    return css`
      width: ${size}px;
      height: ${size}px;
    `
  }}
`

type AvatarImageProps = {
  $isImageLoaded: boolean
}

const AvatarImage = styled.img.withConfig({ displayName: 'Image' })<AvatarImageProps>`
  position: absolute;
  inset: 0;
  object-fit: cover;
  width: 100%;
  height: 100%;
  opacity: ${({ $isImageLoaded }) => ($isImageLoaded ? 1 : 0)};
  transition: opacity ${TIME_150} ease;
`

export type AvatarProps = {
  image?: string
  name?: string
  size: Size
}

export const Avatar = memo(({ image, name, size }: AvatarProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false)
  const [hasImageError, setHasImageError] = useState(false)

  const { fontKind, fontWeight } = avatarSizesDimensions[size]

  const handleOnLoad = useCallback(() => {
    setIsImageLoaded(true)
  }, [])

  const handleOnError = useCallback(() => {
    setHasImageError(true)
  }, [])

  return (
    <AvatarContainer
      $align="center"
      $backgroundColorName="primary-40"
      $borderRadius={BORDER_RADIUS_CIRCLE}
      $justify="center"
      $overflow="hidden"
      $position="relative"
      $size={size}
    >
      {name && (
        <Text
          as="abbr"
          colorName="white"
          fontWeight={fontWeight}
          kind={fontKind}
          textAlign="center"
          textDecoration="none"
          title={name}
        >
          {acronym(name)}
        </Text>
      )}
      {image && !hasImageError && (
        <AvatarImage
          $isImageLoaded={isImageLoaded}
          alt={name}
          aria-label={name}
          loading="lazy"
          onError={handleOnError}
          onLoad={handleOnLoad}
          role="img"
          src={image}
          title={name}
        />
      )}
    </AvatarContainer>
  )
})
