import {
  FloatingFocusManager,
  FloatingOverlay as FloatingUiFloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useId,
  useInteractions,
  useMergeRefs,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react'
import { media, PositionAbsolute, PositionSticky } from 'cdk'
import {
  BORDER_RADIUS_0,
  BORDER_WIDTH_0,
  COLOR_BLACK,
  COLOR_LIGHTER,
  ELEVATION_SM,
  OPACITY_48,
  SPACING_0,
  SPACING_LG,
  SPACING_MD,
  TIME_300_VALUE,
} from 'design-tokens'
import { useBreakpoints } from 'hooks'
import { Icon } from 'icons'
import {
  cloneElement,
  createContext,
  forwardRef,
  type HTMLProps,
  isValidElement,
  type PropsWithChildren,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components'
import { Button } from './Button'
import { Card } from './Card'
import { Text, type TextProps } from './Text'

type DrawerOptions = {
  isOpen?: boolean
  onIsOpenChange?: (isOpen: boolean) => void
}

const useDrawer = ({ isOpen: controlledIsOpen, onIsOpenChange }: DrawerOptions) => {
  const [uncontrolledIsOpen, setUncontrolledIsOpen] = useState(controlledIsOpen ?? false)
  const [labelId, setLabelId] = useState<string | undefined>()
  const [descriptionId, setDescriptionId] = useState<string | undefined>()
  const { isSmallOnly } = useBreakpoints()

  const isOpen = controlledIsOpen ?? uncontrolledIsOpen
  const setIsOpen = onIsOpenChange ?? setUncontrolledIsOpen

  const data = useFloating({ open: isOpen, onOpenChange: setIsOpen })
  const context = data.context

  const click = useClick(context, { enabled: controlledIsOpen == null })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const role = useRole(context)
  const interactions = useInteractions([click, dismiss, role])

  const close = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const { isMounted, styles: modalContainerStyles } = useTransitionStyles(context, {
    duration: parseInt(TIME_300_VALUE),
    initial: { transform: isSmallOnly ? 'translateY(100%)' : 'translateX(100%)', filter: 'blur(3.5px)' },
  })
  const { styles: floatingOverlayStyles } = useTransitionStyles(context, {
    initial: { opacity: '0' },
  })

  return useMemo(
    () => ({
      close,
      isOpen,
      setIsOpen,
      ...interactions,
      ...data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [
      close,
      isOpen,
      setIsOpen,
      interactions,
      data,
      isMounted,
      modalContainerStyles,
      floatingOverlayStyles,
      labelId,
      descriptionId,
    ],
  )
}

type Context = ReturnType<typeof useDrawer> | null

const DrawerContext = createContext<Context>(null)

export const useDrawerContext = () => {
  const context = useContext(DrawerContext)

  if (context === null) {
    throw new Error('Drawer components must be wrapped in <DrawerProvider />')
  }

  return context
}

export const DrawerHeader = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { close, getItemProps, setLabelId } = useDrawerContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <PositionSticky
        {...itemProps}
        ref={ref}
        $backgroundColorName="lighter"
        $direction="row"
        $justify="space-between"
        $py={SPACING_LG}
        $top={0}
        id={id}
      >
        {children}
        <PositionAbsolute $right={SPACING_0} $top={SPACING_LG} $zIndex={1}>
          <Button
            {...props}
            ref={ref}
            aria-label="close drawer"
            isRound={true}
            kind="tertiary"
            onClick={close}
            size="sm"
            type="button"
          >
            <Icon name="xmark" size={24} />
          </Button>
        </PositionAbsolute>
      </PositionSticky>
    )
  },
)

export const DrawerTitle = forwardRef<HTMLElement, Partial<TextProps> & HTMLProps<HTMLElement>>(
  ({ children, ...props }, propRef) => {
    const { getItemProps, setLabelId } = useDrawerContext()
    const id = useId()
    const ref = useMergeRefs([propRef])
    const itemProps = getItemProps(props)

    useLayoutEffect(() => {
      setLabelId(id)

      return () => setLabelId(undefined)
    }, [id, setLabelId])

    return (
      <Text as="h2" kind="h2" {...itemProps} ref={ref} id={id}>
        {children}
      </Text>
    )
  },
)

export const DrawerTrigger = forwardRef<HTMLButtonElement, HTMLProps<HTMLButtonElement> & PropsWithChildren>(
  ({ children, ...props }, propRef) => {
    const { getReferenceProps, isOpen, refs } = useDrawerContext()
    const childrenRef = (children as any).ref
    const ref = useMergeRefs([refs.setReference, propRef, childrenRef])
    const otherProps = {
      'data-state': isOpen ? 'open' : 'closed',
    }

    if (isValidElement(children)) {
      return cloneElement(
        children,
        getReferenceProps({
          ref,
          ...props,
          ...children.props,
          ...otherProps,
        }),
      )
    }

    return (
      <button ref={ref} {...otherProps} {...getReferenceProps(props)}>
        {children}
      </button>
    )
  },
)

// Note: Modals, Popovers, Tooltips, SelectNew must have the same zIndex.
const FloatingOverlay = styled(FloatingUiFloatingOverlay)`
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  ${media.gtSm`
    align-items: center;
    justify-content: flex-end;
  `}

  &::before {
    content: '';
    position: fixed;
    inset: 0;
    background-color: ${COLOR_BLACK};
    opacity: ${OPACITY_48};
  }
`

const DrawerContainer = styled(Card)`
  position: fixed;
  z-index: 102;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  max-height: calc(100vh - 100px);
  padding: ${SPACING_0};
  border: ${BORDER_WIDTH_0};
  box-shadow: ${ELEVATION_SM};

  ${media.sm`
    border-bottom-left-radius: ${BORDER_RADIUS_0};
    border-bottom-right-radius: ${BORDER_RADIUS_0};
  `}

  ${media.gtSm`
    top: ${SPACING_MD};
    right: ${SPACING_MD};
    height: 100vh;
    max-height: calc(100vh - ${SPACING_MD} * 2);
    width: calc(100% - 150px);
    max-width: 600px;
  `}
`

const DrawerContentWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  min-height: 0;
  padding: ${SPACING_0} ${SPACING_LG} ${SPACING_LG} ${SPACING_LG};
  background-color: ${COLOR_LIGHTER};
`

export const Drawer = forwardRef<HTMLDivElement, PropsWithChildren>(({ children, ...props }, propRef) => {
  const {
    context,
    descriptionId,
    floatingOverlayStyles,
    getFloatingProps,
    isMounted,
    labelId,
    modalContainerStyles,
    refs,
  } = useDrawerContext()

  const ref = useMergeRefs([refs.setFloating, propRef])

  if (!isMounted) {
    return null
  }

  return (
    <FloatingPortal>
      <FloatingOverlay lockScroll style={floatingOverlayStyles}>
        <FloatingFocusManager context={context}>
          <DrawerContainer
            ref={ref}
            aria-describedby={descriptionId}
            aria-labelledby={labelId}
            aria-modal="true"
            size="md"
            {...getFloatingProps(props)}
            style={modalContainerStyles}
          >
            <DrawerContentWrapper>{children}</DrawerContentWrapper>
          </DrawerContainer>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})

export type DrawerProps = PropsWithChildren<DrawerOptions>

export const DrawerProvider = ({ children, ...options }: DrawerProps) => {
  const modal = useDrawer(options)

  return <DrawerContext.Provider value={modal}>{children}</DrawerContext.Provider>
}
