import { type ReactNode } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Flex, PageLayout } from 'ui-deprecated'
import { ErrorAppBar } from '~/components/AppBar/components/ErrorAppBar'
import { IntercomProvider } from '~/domains/intercom/components/IntercomProvider'
import { LanguageProvider } from '~/i18n/hooks/useLanguage'

type Props = {
  children: ReactNode
}

export const ErrorPage = ({ children }: Props) => (
  <BrowserRouter>
    <LanguageProvider>
      <IntercomProvider>
        <Flex px={16}>
          <ErrorAppBar />
        </Flex>

        <PageLayout maxWidth="640px">{children}</PageLayout>
      </IntercomProvider>
    </LanguageProvider>
  </BrowserRouter>
)
