import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const LogoFacebook = ({ height, width }: Props) => (
  <svg
    data-test-id="icon-logo-facebook"
    fill="none"
    height={height}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#icon-logo-facebook-clip)">
      <path
        clipRule="evenodd"
        d="M32 20.0707C32 13.4042 26.6274 8 20 8C13.3726 8 8 13.4042 8 20.0707C8 26.0956 12.3882 31.0893 18.125 31.9948V23.5599H15.0781V20.0707H18.125V17.4114C18.125 14.3862 19.9165 12.7151 22.6576 12.7151C23.9705 12.7151 25.3438 12.9509 25.3438 12.9509V15.9214H23.8306C22.3399 15.9214 21.875 16.8519 21.875 17.8065V20.0707H25.2031L24.6711 23.5599H21.875V31.9948C27.6118 31.0893 32 26.0956 32 20.0707Z"
        fill="#1877F2"
        fillRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="icon-logo-facebook-clip">
        <rect fill="white" height="24" transform="translate(8 8)" width="24" />
      </clipPath>
    </defs>
  </svg>
)
