import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PathPsychiatry = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-psychiatry"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M20 37.14a7.14 7.14 0 1 0 0-14.28 7.14 7.14 0 0 0 0 14.28ZM37.14 20c0 6.24-3.33 11.7-8.3 14.7a10 10 0 1 0-17.66 0 17.13 17.13 0 0 1 8.82-31.84c9.47 0 17.14 7.67 17.14 17.14ZM20 40a20 20 0 1 0 0-40 20 20 0 0 0 0 40Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
