import { TIME_300 } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Text } from '../Text'

export type HintProps = PropsWithChildren<{
  /** Use `id` for `aria-labelledby` together with Form Fields @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  id?: string
}>

/**
 * Should be used with Form Fields with `id` for `aria-labelledby`
 */
export const Hint = styled(Text)
  .withConfig({ displayName: 'Hint' })
  .attrs(({ colorName = 'neutral-80' }) => ({
    colorName,
    as: 'small',
    fontWeight: '500',
    kind: 'footnote',
  }))<HintProps>`
  transition: color ${TIME_300} ease-out;
`
