import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const Maestro = ({ width }: Props) => (
  <svg
    data-test-id="icon-maestro"
    fill="none"
    version="1.1"
    viewBox="0 0 22 14"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.3047 1.50661H8.63733V11.6917H14.3047V1.50661Z" fill="#7673C0" />
    <path
      d="M8.99818 6.59971C8.99741 5.61897 9.21974 4.6509 9.64833 3.76877C10.0769 2.88664 10.7006 2.11356 11.4721 1.50804C10.5165 0.7571 9.36902 0.290135 8.16066 0.160516C6.95231 0.0308959 5.73185 0.243847 4.6388 0.775035C3.54575 1.30622 2.62419 2.13422 1.97945 3.16438C1.33471 4.19454 0.992798 5.38532 0.992798 6.60061C0.992798 7.8159 1.33471 9.00667 1.97945 10.0368C2.62419 11.067 3.54575 11.895 4.6388 12.4262C5.73185 12.9574 6.95231 13.1703 8.16066 13.0407C9.36902 12.9111 10.5165 12.4441 11.4721 11.6932C10.7003 11.0875 10.0765 10.3141 9.64794 9.43163C9.21933 8.54917 8.99714 7.58075 8.99818 6.59971V6.59971Z"
      fill="#EB001B"
    />
    <path
      d="M21.3332 10.6123V10.4036H21.4177V10.3604H21.2036V10.4036H21.2882V10.6123H21.3332ZM21.7488 10.6123V10.3604H21.684L21.6084 10.5403L21.5329 10.3604H21.4663V10.6123H21.5131V10.4233L21.5832 10.5871H21.6318L21.702 10.4233V10.6141L21.7488 10.6123Z"
      fill="#00A1DF"
    />
    <path
      d="M21.9519 6.59849C21.9519 7.81387 21.6099 9.00472 20.965 10.0349C20.3202 11.0651 19.3985 11.8931 18.3053 12.4242C17.2121 12.9553 15.9915 13.1681 14.7831 13.0383C13.5747 12.9085 12.4271 12.4413 11.4717 11.6902C12.2429 11.0841 12.8663 10.3108 13.295 9.42862C13.7237 8.54644 13.9465 7.57842 13.9465 6.59759C13.9465 5.61676 13.7237 4.64873 13.295 3.76655C12.8663 2.88437 12.2429 2.11108 11.4717 1.50502C12.4273 0.753752 13.5749 0.286537 14.7835 0.156799C15.9921 0.0270607 17.2128 0.240037 18.3061 0.771368C19.3993 1.3027 20.321 2.13094 20.9657 3.16141C21.6105 4.19187 21.9522 5.38296 21.9519 6.59849Z"
      fill="#00A1DF"
    />
  </svg>
)
