import { type LocalStorageItemValue, type LocalStorageKey } from './types'

export function setLocalStorage<T extends LocalStorageItemValue>(key: LocalStorageKey, value: T): T | null {
  try {
    window.localStorage.setItem(`srns-${key}`, typeof value === 'string' ? value : String(value))

    return value
  } catch (error) {
    return null
  }
}
