import { type ButtonKind, type ColorName, colors } from '@serenis-health/tokens'
import { assertNever } from 'functions'
import { css } from 'styled-components'
import { type ButtonProps } from '../types/button'

type Param = {
  kind: ButtonKind
}

type Prop = {
  backgroundColor: ColorName
  borderColor: ColorName
  colorName: ColorName
}

/** @deprecated */
export type ButtonPropsByKind = {
  base: Prop
  disabled?: Prop
  hover: Prop
  loading: ColorName
  opacity?: number
  pressed?: Prop
}

/** @deprecated */
export function getButtonPropsByKind({ kind }: Param): ButtonPropsByKind {
  switch (kind) {
    case 'danger':
      return {
        base: {
          backgroundColor: 'coral10',
          borderColor: 'coral10',
          colorName: 'white',
        },
        disabled: {
          backgroundColor: 'transparent',
          borderColor: 'coral10',
          colorName: 'coral10',
        },
        hover: {
          backgroundColor: 'coral08',
          borderColor: 'coral08',
          colorName: 'white',
        },
        loading: 'coral10',
      }
    case 'none':
      return {
        base: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          colorName: 'black',
        },
        disabled: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          colorName: 'grey08',
        },
        hover: {
          backgroundColor: 'transparent',
          borderColor: 'transparent',
          colorName: 'grey10',
        },
        loading: 'black',
      }
    case 'white':
      return {
        base: {
          backgroundColor: 'white',
          borderColor: 'white',
          colorName: 'purple08',
        },
        disabled: {
          backgroundColor: 'transparent',
          borderColor: 'white',
          colorName: 'purple08',
        },
        hover: {
          backgroundColor: 'purple04',
          borderColor: 'purple04',
          colorName: 'purple08',
        },
        loading: 'purple08',
      }
    case 'white-stroke':
      return {
        base: {
          backgroundColor: 'transparent',
          borderColor: 'white',
          colorName: 'white',
        },
        disabled: {
          backgroundColor: 'white',
          borderColor: 'white',
          colorName: 'white',
        },
        hover: {
          backgroundColor: 'transparent',
          borderColor: 'green10',
          colorName: 'green10',
        },
        loading: 'green10',
      }
    case 'primary':
      return {
        base: {
          backgroundColor: 'purple08',
          borderColor: 'purple08',
          colorName: 'white',
        },
        hover: {
          backgroundColor: 'purple04',
          borderColor: 'purple04',
          colorName: 'purple08',
        },
        pressed: {
          backgroundColor: 'purple10',
          borderColor: 'purple10',
          colorName: 'white',
        },
        opacity: 0.4,
        loading: 'white',
      }
    case 'secondary':
      return {
        base: {
          backgroundColor: 'transparent',
          borderColor: 'purple04',
          colorName: 'purple08',
        },
        hover: {
          backgroundColor: 'purple04',
          borderColor: 'purple04',
          colorName: 'purple08',
        },
        pressed: {
          backgroundColor: 'transparent',
          borderColor: 'purple10',
          colorName: 'purple08',
        },
        opacity: 0.4,
        loading: 'purple08',
      }
    default:
      return assertNever(kind)
  }
}

/** @deprecated */
export const buttonWrapperStyles = css<ButtonProps & { colorState: ButtonPropsByKind }>`
  /* stylelint-disable no-descending-specificity */

  ${({ colorState }) => `
    background-color: ${colors[colorState.base.backgroundColor]};
    border-color: ${colors[colorState.base.borderColor]};
    color: ${colors[colorState.base.colorName]};
  `}

  &:hover {
    &:not([disabled]) {
      ${({ colorState }) => `
        background-color: ${colors[colorState.hover.backgroundColor]};
        border-color: ${colors[colorState.hover.borderColor]};
        color: ${colors[colorState.hover.colorName]};
      `}
    }
  }

  &:active {
    &:not([disabled]) {
      ${({ colorState }) => `
        background-color: ${colors[(colorState.pressed || colorState.hover).backgroundColor]};
        border-color: ${colors[(colorState.pressed || colorState.hover).borderColor]};
        color: ${colors[(colorState.pressed || colorState.hover).colorName]};
      `}
    }
  }

  &:disabled,
  &[disabled] {
    ${({ colorState }) => `
      background-color: ${colors[(colorState.disabled || colorState.base).backgroundColor]};
      border-color: ${colors[(colorState.disabled || colorState.base).borderColor]};
      color: ${colors[(colorState.disabled || colorState.base).colorName]};
      cursor: auto;
      ${colorState.opacity && `opacity: ${colorState.opacity};`}
    `}
  }

  /* stylelint-disable-next-line order/order */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  height: ${({ size }) => (size === 'medium' ? 52 : 42)}px;
  padding: ${({ size }) => `${size === 'medium' ? 16 : 12}px 20px`};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
`
