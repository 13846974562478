import { type ColorName, cssvarColor, cssvarTypography, type TypographyName } from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled, { type CSSProperties } from 'styled-components'
import { shouldForwardProp } from './utils/shouldForwardProp'

export type { TypographyName } from 'design-tokens'

export type TextProps = PropsWithChildren<{
  breakWord?: boolean
  colorName?: ColorName
  ellipsis?: boolean
  fontStyle?: CSSProperties['fontStyle']
  fontWeight?: '400' | '500' | '600'
  kind?: TypographyName
  newLine?: boolean
  textAlign?: CSSProperties['textAlign']
  textDecoration?: CSSProperties['textDecorationLine']
  textTransform?: CSSProperties['textTransform']
  whiteSpace?: CSSProperties['whiteSpace']
}>

export const Text = styled.span.withConfig({
  displayName: 'Text',
  shouldForwardProp,
})<TextProps>`
  ${({ kind }) => kind && cssvarTypography(kind)};
  ${({ colorName }) => colorName && `color: ${cssvarColor(colorName)};`}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`};
  ${({ textDecoration }) => textDecoration && `text-decoration-line: ${textDecoration};`};
  ${({ newLine }) => newLine && 'white-space: pre-wrap;'};
  ${({ breakWord }) => breakWord && 'word-break: break-word;'};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace};`};
  ${({ ellipsis }) => ellipsis && 'max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;'}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle};`};
`
