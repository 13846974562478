import { noop } from 'functions'
import { forwardRef, type Ref } from 'react'
import { HiddenInput } from './components/HiddenInput'
import { StyledLabel } from './components/StyledLabel'
import { StyledView } from './components/StyledView'
import { type SwitchProps } from './types'

/** @deprecated */
export const SwitchField = forwardRef(
  (
    {
      checked = false,
      disabled = false,
      invalid = false,
      name,
      onChange = noop,
      required = false,
      value = '',
    }: SwitchProps,
    ref: Ref<HTMLInputElement>,
  ) => (
    <StyledView>
      <HiddenInput ref={ref} {...{ checked, disabled, invalid, id: name, name, onChange, required, value }} />

      <StyledLabel checked={checked} disabled={disabled} invalid={invalid} name={name} />
    </StyledView>
  ),
)
