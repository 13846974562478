import { type ColorName } from '@serenis-health/tokens'
import { type CheckboxProps } from '../types'

/** @deprecated */
export const getBorderColorName = ({ checked, disabled, invalid }: CheckboxProps): ColorName => {
  if (disabled && checked) {
    return 'grey06'
  }

  if (disabled) {
    return 'grey04'
  }

  if (invalid) {
    return 'coral08'
  }

  if (!checked) {
    return 'grey08'
  }

  return 'purple08'
}
