import styled, { css } from 'styled-components'
import { type TextProps } from '../types/text'
import { getColorByColorName, getColorHoverByColorName } from '../utils/getColorByColorName'
import { getDisabledOpacity } from '../utils/getDisabledOpacity'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { typography } from '../utils/typography'

const defaultProps: Required<Pick<TextProps, 'kind'>> = {
  kind: 'paragraph',
}

/** @deprecated Import `ui` one */
export const Text = styled.span
  .withConfig({
    displayName: 'Text',
    shouldForwardProp,
  })
  .attrs((props: TextProps) => ({
    ...defaultProps,
    ...props,
  }))<TextProps>`
  ${typography}
  ${getDisabledOpacity}
  ${getColorByColorName}
  ${({ breakWord }) => breakWord && 'word-break: break-word;'}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle}`};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ newLine }) => newLine && 'white-space: pre-wrap;'}
  ${({ textDecoration }) => textDecoration && `text-decoration-line: ${textDecoration};`}
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ colorName, colorNameHover, link }) =>
    link &&
    css`
      &:hover {
        ${getColorHoverByColorName({ colorName, colorNameHover })}
        outline: none;
        cursor: pointer;
      }
    `}
  ${({ ellipsis }) =>
    ellipsis &&
    `
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
`
