import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const QuestionMarkCircle = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path
        d="M12 15v-1c0-1 3-1.5 3-4.253C15 8.23 13.657 7 12 7S9 8.23 9 9.747"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
      <path d="M13 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" fill={color} />
    </g>
  </svg>
)
