import { isNeitherNullNorUndefined } from 'functions'
import { css, type CSSProperties } from 'styled-components'
import { type ResponsiveProps, type StyledProps } from '../utils/props'

type BaseDisplayProps = {
  hide?: boolean
}

type OverflowProps = {
  overflow?: CSSProperties['overflow']
}

type OpacityProps = {
  opacity?: CSSProperties['opacity']
}

type MdDisplayProps = ResponsiveProps<BaseDisplayProps, 'md'>
type LgDisplayProps = ResponsiveProps<BaseDisplayProps, 'lg'>
type XlDisplayProps = ResponsiveProps<BaseDisplayProps, 'xl'>

export type DisplayProps = StyledProps<
  BaseDisplayProps & OpacityProps & OverflowProps & MdDisplayProps & LgDisplayProps & XlDisplayProps
>

export const generateDisplayStyles = ({
  hide,
  opacity,
  overflow,
}: BaseDisplayProps & OpacityProps & OverflowProps) => css`
  ${isNeitherNullNorUndefined(hide) &&
  css`
    display: ${hide ? 'none' : 'flex'};
  `}
  overflow: ${overflow};
  opacity: ${opacity};
  ${overflow === 'auto' &&
  css`
    scroll-behavior: smooth;
  `}
`
