import { type ColorName } from '@serenis-health/tokens'
import { type CheckboxProps } from '../types'

/** @deprecated */
export const getBackgroundColorName = ({ checked, disabled }: CheckboxProps): ColorName => {
  if (disabled && checked) {
    return 'grey04'
  }

  if (disabled) {
    return 'grey02'
  }

  if (!checked) {
    return 'white'
  }

  return 'purple08'
}
