import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisShippingBox = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.28427 19.408c.04289.5979.29983 1.1604.72373 1.5843.42389.4239.98635.6808 1.58429.7237 1.5751.1254 4.04225.2589 7.40811.2589 3.3654 0 5.833-.1335 7.4081-.2589.5979-.0429 1.1604-.2998 1.5843-.7237.4239-.4239.6808-.9864.7237-1.5843.1254-1.5751.2589-4.0422.2589-7.4081 0-3.36537-.1335-5.833-.2589-7.4081-.0429-.59794-.2998-1.16039-.7237-1.58429-.4239-.42389-.9864-.68083-1.5843-.72373-1.5751-.1254-4.0423-.25888-7.4081-.25888-3.36538 0-5.83301.13348-7.40811.25888-.59794.0429-1.1604.29984-1.58429.72373-.4239.4239-.68084.98635-.72373 1.58429-.1254 1.5751-.25888 4.04225-.25888 7.4081 0 3.3654.13348 5.833.25888 7.4081Z"
      stroke={color}
      strokeLinecap="round"
      strokeWidth="1.4"
    />
    <path
      d="M18.1723 2.5c-.0214 2.0235-.1682 4.66087-.2532 5.71205-.0063.07756-.0318.15234-.0742.21758-.0425.06525-.1004.1189-.1688.15614-.0683.03723-.1448.05686-.2227.05713-.0778.00027-.1545-.01884-.223-.0556l-2.1185-2.12053c-.0342-.01805-.0723-.02749-.111-.02749-.0386 0-.0767.00944-.1109.02749l-2.1175 2.121c-.0686.03676-.1453.05587-.2231.0556-.0778-.00026-.1543-.0199-.2227-.05713-.0683-.03723-.1263-.09089-.1687-.15613-.0425-.06525-.068-.14002-.0743-.21759-.0855-1.05165-.2318-3.68902-.2536-5.71252"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path d="M5 14h14M5 18h11M5 10h4" stroke={color} strokeLinecap="round" strokeWidth="1.4" />
  </svg>
)
