import styled from 'styled-components'
import { shouldForwardProp } from '../../../utils/shouldForwardProp'
import { Flex, type FlexProps } from '../../Flex'
import { type CheckboxProps } from '../types'
import { getBackgroundColorName } from '../utils/getBackgroundColorName'
import { getBorderColorName } from '../utils/getBorderColorName'

/** @deprecated */
export const StyledChoice = styled(Flex)
  .withConfig({
    displayName: 'StyledChoice',
    shouldForwardProp,
  })
  .attrs<CheckboxProps & { type: 'checkbox' | 'radio' }>((props) => ({
    ...props,
    align: 'center',
    justify: 'center',
    background: props.type === 'radio' ? 'white' : getBackgroundColorName(props),
    borderColor: props.type === 'radio' ? 'grey08' : getBorderColorName(props),
    borderRadius: props.type === 'radio' ? 24 : 6,
    borderSize: 1,
  }))<Omit<FlexProps, 'align' | 'background' | 'justify'> & CheckboxProps & { type: 'checkbox' | 'radio' }>`
  height: 24px;
  width: 24px;
`
