import { useCallback } from 'react'
import { type IntercomProps, type IntercomSpace, useIntercom as useIntercomHook } from 'react-use-intercom'
import { type FeatureName, type FeatureValue } from '~/domains/featureFlags'
import { useIntercomState } from '~/domains/intercom/hooks/useIntercomState'
import { useDataLayer } from '~/hooks/useDataLayer'
import { useTranslate } from '~/i18n/hooks/useTranslation'
import { type TranslationId, type TranslationValues } from '~/i18n/types'

type CustomAttributes = {
  'User Type'?: 'Patient' | 'Therapist'
  /**
   * @note Intercom is not able to save list as array so we need to pass a comma separated list as string
   * @link https://www.intercom.com/help/en/articles/179-create-and-track-custom-data-attributes-cdas
   */
  'Therapist Profession Types'?: string
  srns_change_therapist_auto?: boolean
  srns_change_nutritionist?: boolean
  srns_videocall_issue?: boolean
  sync_fingerprint_id?: string
} & Partial<Record<FeatureName, FeatureValue>>

export const useIntercom = () => {
  const { add } = useDataLayer()
  const { messagesVisible } = useIntercomState()
  const {
    boot,
    hide,
    show,
    showNewMessage,
    showSpace,
    shutdown,
    startTour,
    trackEvent,
    update: updateHook,
  } = useIntercomHook()
  const translate = useTranslate()

  const update = useCallback(
    ({
      customAttributes,
      userHash,
      userId,
      ...props
    }: Omit<IntercomProps, 'customAttributes'> & { customAttributes?: CustomAttributes }) =>
      updateHook({
        ...props,
        customAttributes,
        userHash,
        userId,
      }),
    [updateHook],
  )

  const showDefaultLauncher = useCallback(() => {
    update({
      hideDefaultLauncher: false,
      customAttributes: {
        // NOTE default value to false to avoid triggering the associated workflow a second time
        srns_change_therapist_auto: false,
        srns_change_nutritionist: false,
        srns_videocall_issue: false,
      },
    })
  }, [update])

  const open = useCallback(
    (space?: IntercomSpace) => {
      add({ event: 'SUPPORT_OPEN' }, 'support-open')

      if (space) {
        showSpace(space)
      } else {
        show()
      }

      showDefaultLauncher()
    },
    [add, showDefaultLauncher, showSpace, show],
  )

  const openWithMessage = useCallback(
    (id?: TranslationId, values?: TranslationValues, customMessage?: string | null) => {
      add({ event: 'SUPPORT_REQUEST' }, 'support-request')

      const translation = id && translate(id, values)

      const message = translation && customMessage ? `${translation}\n\n${customMessage}` : translation

      showNewMessage(message)

      showDefaultLauncher()
    },
    [add, showDefaultLauncher, showNewMessage, translate],
  )

  const openWithCustomMessage = useCallback(
    (message: string) => {
      add({ event: 'SUPPORT_REQUEST' }, 'support-request')

      showNewMessage(message)

      showDefaultLauncher()
    },
    [add, showDefaultLauncher, showNewMessage],
  )

  const hideDefaultLauncher = useCallback(() => {
    if (messagesVisible) {
      hide()
    }

    updateHook({
      hideDefaultLauncher: true,
    })
  }, [hide, messagesVisible, updateHook])

  const logout = useCallback(() => {
    shutdown()
    boot()
  }, [boot, shutdown])

  return {
    close: hide,
    destroy: shutdown,
    hideDefaultLauncher,
    logout,
    open,
    openWithMessage,
    openWithCustomMessage,
    showDefaultLauncher,
    startTour,
    trackEvent,
    update,
  }
}
