import { type TextAlign } from '@serenis-health/tokens'
import { noop } from 'functions'
import { forwardRef, type Ref } from 'react'
import { useInput } from '../../hooks/useInput'
import { type AutoComplete } from '../../types/_index'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { Underline } from '../Underline'
import { Input } from './components/Input'

type Props = {
  autoComplete?: AutoComplete
  disabled?: boolean
  errorMessage?: string
  helperText?: string
  inputMode?: 'decimal' | 'email' | 'numeric' | 'search' | 'tel' | 'text' | 'url' | 'none'
  invalid?: boolean
  label?: string
  name: string
  onBlur?: () => void
  onChange?: (value: string) => void
  onFocus?: () => void
  placeholder?: string
  readOnly?: boolean
  textAlign?: TextAlign
  type: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url'
  value: string
  warning?: boolean
}

/** @deprecated */
export const TextField = forwardRef(
  (
    {
      autoComplete,
      disabled = false,
      errorMessage = '',
      helperText = '',
      inputMode,
      invalid = false,
      label,
      name,
      onBlur = noop,
      onChange = noop,
      onFocus = noop,
      placeholder,
      readOnly = false,
      textAlign = 'left',
      type,
      value,
      warning = false,
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { error, inputColorName, handleBlur, handleChange, handleFocus, labelColorName, underlineColorName } =
      useInput({
        disabled,
        invalid,
        onBlur,
        onChange,
        onFocus,
        readOnly,
        warning,
      })

    return (
      <Flex>
        {label && (
          <Text align={textAlign} as="label" colorName={labelColorName} htmlFor={name} kind="label">
            {label}
          </Text>
        )}

        <Input
          ref={ref}
          autoComplete={autoComplete}
          colorName={inputColorName}
          disabled={disabled}
          id={name}
          inputMode={inputMode}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          textAlign={textAlign}
          type={type}
          value={value}
        />

        <Underline colorName={underlineColorName} />

        {errorMessage && error && (
          <Flex pt={8}>
            <Text align={textAlign} colorName="coral10" kind="label">
              {errorMessage}
            </Text>
          </Flex>
        )}

        {helperText && (
          <Flex pt={8}>
            <Text align={textAlign} colorName="grey10" kind="label">
              {helperText}
            </Text>
          </Flex>
        )}
      </Flex>
    )
  },
)
