import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisSticker = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.3 2.02c.82.02 1.44.74 1.35 1.56l-.11.93a1.4 1.4 0 0 1-1.4 1.24H9.67a1.4 1.4 0 0 1-1.4-1.24l-.1-.93c-.1-.82.52-1.54 1.34-1.56a117.85 117.85 0 0 1 4.8 0Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M8.16 3.46c-1.27.05-2.24.1-2.96.17-1.08.09-1.88.89-1.98 1.97C3.13 6.9 3 9.1 3 12.55s.11 5.63.23 6.95c.09 1.08.9 1.87 1.97 1.96 1.3.11 3.44.23 6.7.23 3.28 0 5.4-.12 6.71-.23a2.12 2.12 0 0 0 1.98-1.96c.11-1.32.22-3.5.22-6.95 0-3.46-.11-5.64-.22-6.95a2.12 2.12 0 0 0-1.98-1.97c-.72-.06-1.68-.12-2.96-.17"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M16.5 17.44c0 1.1-2.3 1.56-4.5 1.56s-4.5-.45-4.5-1.56c0-1.1 1.3-2 4.5-2s4.5.9 4.5 2ZM14.44 11.04a2.44 2.44 0 1 1-4.88 0 2.44 2.44 0 0 1 4.88 0Z"
      stroke={color}
      strokeWidth="1.4"
    />
  </svg>
)
