import { type ColorName, toColorName } from '@serenis-health/tokens'
import styled from 'styled-components'
import { shouldForwardProp } from '../utils/shouldForwardProp'

type Attrs = {
  colorName?: ColorName
  listStyleType?: 'disc' | 'none'
}

/** @deprecated */
export const UnorderedList = styled.ul.withConfig({
  displayName: 'UnorderedList',
  shouldForwardProp,
})<Attrs>`
  padding-left: 16px;
  color: ${({ colorName }) => (colorName ? toColorName(colorName) : 'currentColor')};
  list-style-type: ${({ listStyleType = 'disc' }) => listStyleType};
`
