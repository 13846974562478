import styled from 'styled-components'
import { shouldForwardProp } from '../utils/shouldForwardProp'
import { Flex, type FlexProps } from './Flex'

type Attrs = {
  bottom?: string
  left?: string
  right?: string
  top?: string
  zIndex?: number
}

/** @deprecated Use the `cdk` one */
export const PositionAbsolute = styled(Flex).withConfig({ shouldForwardProp })<FlexProps & Attrs>`
  position: absolute;
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ left }) => left && `left: ${left};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
`

PositionAbsolute.displayName = 'PositionAbsolute'
