import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisStickerSquare = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.43 21.711a2.316 2.316 0 0 1-2.141-2.141c-.127-1.527-.264-4-.264-7.57 0-3.57.137-6.044.264-7.57a2.316 2.316 0 0 1 2.14-2.141c1.528-.127 4.002-.264 7.571-.264 3.57 0 6.044.137 7.57.264a2.316 2.316 0 0 1 2.141 2.14c.127 1.527.264 4.002.264 7.57 0 1.391.033 2.734 0 3.8L15.8 21.976c-1.067.033-2.41 0-3.8 0-3.57 0-6.043-.137-7.57-.264Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M15.8 14.375c-2.099 2.533-5.501 2.533-7.6 0M8.2 8.675v.95M15.8 8.675v.95M21.975 15.8c-1.638.05-2.898.138-3.812.225a2.34 2.34 0 0 0-2.138 2.138 60.507 60.507 0 0 0-.225 3.812"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
