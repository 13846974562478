import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const PathNutritionDca = ({ color, width }: Props) => (
  <svg
    data-test-id="icon-path-nutrition-dca"
    fill="none"
    height={width}
    viewBox="0 0 40 40"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M37.14 17.14A14.29 14.29 0 0 1 22.5 31.42 11.45 11.45 0 0 0 8.58 17.5v-.36a14.29 14.29 0 1 1 28.56 0Zm-31.36 1.5a17.14 17.14 0 1 1 15.59 15.59 11.42 11.42 0 1 1-15.6-15.6ZM18.3 33.67a8.56 8.56 0 0 1-15.45-5.1 8.56 8.56 0 0 1 3.46-6.9 17.18 17.18 0 0 0 12 12Zm1.32-2.62a14.3 14.3 0 0 1-10.7-10.7 8.57 8.57 0 0 1 10.7 10.7Z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
