import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisChat = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path
        d="M4.167 18.802c-1.194-.084-2.127-.974-2.236-2.166-.122-1.343-.243-3.401-.243-6.277 0-2.875.121-4.933.243-6.276.109-1.192 1.042-2.082 2.236-2.166 1.578-.11 4.138-.23 7.833-.23 3.695 0 6.255.12 7.832.23 1.195.084 2.128.974 2.237 2.166.122 1.343.244 3.4.244 6.276s-.122 4.934-.244 6.277c-.108 1.192-1.042 2.082-2.236 2.166-1.476.103-3.81.214-7.128.228l-3.376 2.893a.938.938 0 0 1-1.548-.711v-2.239a89.97 89.97 0 0 1-3.614-.171Z"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth="1.4"
      />
    </g>
  </svg>
)
