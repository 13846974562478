import styled from 'styled-components'
import { Flex, type FlexProps } from '../../Flex'
import { type CheckboxProps } from '../types'
import { getBackgroundColorName } from '../utils/getBackgroundColorName'

/** @deprecated */
export const StyledChoiceDot = styled(Flex).attrs<CheckboxProps & { type: 'checkbox' | 'radio' }>((props) => ({
  ...props,
  background: getBackgroundColorName(props),
  borderRadius: props.type === 'radio' ? 24 : 6,
}))<Omit<FlexProps, 'align' | 'background' | 'justify'> & CheckboxProps & { type: 'checkbox' | 'radio' }>`
  width: ${({ type }) => (type === 'checkbox' ? 24 : 16)}px;
  height: ${({ type }) => (type === 'checkbox' ? 24 : 16)}px;
`
