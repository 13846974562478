import {
  BORDER_RADIUS_SM,
  BORDER_RADIUS_XS,
  BORDER_WIDTH_1,
  type ColorName,
  cssvarColor,
  SPACING_LG,
  SPACING_MD,
  SPACING_XL,
  SPACING_XS,
} from 'design-tokens'
import { type PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { shouldForwardProp } from './utils/shouldForwardProp'

type Size = 'xs' | 'sm' | 'md' | 'lg'

export const cardSizesDimensions: Record<Size, { borderRadius: string; padding: string }> = {
  xs: { borderRadius: BORDER_RADIUS_XS, padding: SPACING_XS },
  sm: { borderRadius: BORDER_RADIUS_SM, padding: SPACING_MD },
  md: { borderRadius: BORDER_RADIUS_SM, padding: SPACING_LG },
  lg: { borderRadius: BORDER_RADIUS_SM, padding: SPACING_XL },
}

export type CardProps = PropsWithChildren<{
  backgroundColorName?: ColorName
  borderColorName?: ColorName
  size?: Size
}>

export const Card = styled.div.withConfig({ displayName: 'Card', shouldForwardProp })<CardProps>`
  ${({ size = 'md' }) => css`
    padding: ${cardSizesDimensions[size].padding};
    border-radius: ${cardSizesDimensions[size].borderRadius};
  `};
  border: ${BORDER_WIDTH_1} solid ${({ borderColorName = 'primary-40' }) => cssvarColor(borderColorName)};
  background-color: ${({ backgroundColorName = 'lighter' }) => cssvarColor(backgroundColorName)};
`
