import { type ColorName, cssvarColor } from 'design-tokens'
import { Svg } from './components/Svg'

type LogoColorName = Extract<ColorName, 'black' | 'darker' | 'lighter' | 'primary' | 'white'>

type DoubleColorProps = {
  primaryColorName: Extract<LogoColorName, 'primary'>
  secondaryColorName: Extract<LogoColorName, 'darker'>
}

type SingleColorProps = {
  primaryColorName: LogoColorName
  secondaryColorName?: never
}

type LogoProps = DoubleColorProps | SingleColorProps

type Props = {
  height: number
} & LogoProps

export const SerenisLogoHorizontal = ({ height, primaryColorName, secondaryColorName }: Props) => {
  const primaryFill = cssvarColor(primaryColorName)
  const secondaryFill = cssvarColor(secondaryColorName ?? primaryColorName)

  return (
    <Svg height={height} viewBox="0 0 553.33 120">
      <path
        d="M172.13 80.32 181 69.9c6.13 6.38 12.25 9.25 19.16 9.25 5.87 0 9.52-2.08 9.52-5.6 0-3.26-3.13-5-11.08-6.91C182.82 63 176 57 176 47.09c0-11.47 9-18.64 23.2-18.64a35.53 35.53 0 0 1 24.8 9.39L215.66 48c-5.6-5.08-11.21-7.17-17.07-7.17-5.22 0-8.21 1.83-8.21 5.09s2.73 4.82 10.55 6.51c16.69 3.78 23.33 10.17 23.33 19.94 0 11.86-9.77 19.16-25 19.16-10.97 0-20.48-3.78-27.13-11.21ZM231.43 60.12c0-18.24 12.9-31.67 30.5-31.67 16.68 0 29.45 12.12 29.45 29.85a33.58 33.58 0 0 1-.52 5.86h-44.57c1.56 8.74 7.55 14 17.46 14A21 21 0 0 0 281 70l8.87 9c-6.39 8.21-16 12.51-26.85 12.51-18.43.02-31.59-13.14-31.59-31.39Zm30.1-19.94c-7.95 0-13.42 5-15.11 12.9h30.1c-1.69-8.34-7.04-12.9-14.99-12.9ZM316.14 30v8.21c3.78-6.52 9.12-9.78 16.29-9.78a31.14 31.14 0 0 1 4.56.39L335 44.22a16.38 16.38 0 0 0-5.61-.91c-7.82 0-12.64 5.47-12.64 13.82V90h-14.3V30ZM338.94 60.12c0-18.24 12.91-31.67 30.5-31.67 16.68 0 29.46 12.12 29.46 29.85a32.73 32.73 0 0 1-.53 5.86H353.8c1.56 8.74 7.56 14 17.47 14a21 21 0 0 0 17.2-8.16l8.86 9c-6.38 8.21-16 12.51-26.85 12.51-18.37.02-31.54-13.14-31.54-31.39Zm30.11-19.94c-7.95 0-13.42 5-15.12 12.9H384c-1.66-8.34-7-12.9-14.95-12.9ZM423.65 30v6.77a21.39 21.39 0 0 1 17.47-8.34c13.42 0 22.94 10.56 22.94 24.5V90h-14.34V56.21c0-9-4.82-14.72-12.64-14.72s-12.77 6-12.77 15V90H410V30Z"
        fill={secondaryFill}
      />
      <circle cx="484.9" cy="12.15" fill={secondaryFill} r="8.86" />
      <path
        d="M477.74 30.02h14.34v59.95h-14.34zM501.19 80.32l8.87-10.42c6.12 6.38 12.25 9.25 19.16 9.25 5.86 0 9.51-2.08 9.51-5.6 0-3.26-3.13-5-11.08-6.91C511.88 63 505.1 57 505.1 47.09c0-11.47 9-18.64 23.2-18.64a35.55 35.55 0 0 1 24.77 9.39L544.73 48c-5.61-5.08-11.21-7.17-17.08-7.17-5.21 0-8.21 1.83-8.21 5.09s2.74 4.82 10.56 6.51c16.68 3.78 23.33 10.17 23.33 19.94 0 11.86-9.78 19.16-25 19.16-10.97 0-20.49-3.78-27.14-11.21Z"
        fill={secondaryFill}
      />
      <path
        d="M60.37 69.38c15.85 4 9.8 11.12 9 11.89-9.15 8.46-38.13 17-60.52 22.94C2.28 95 0 80.91 0 60 0 12 12 0 60 0c11.53 0 21 .69 28.68 2.41a46.14 46.14 0 0 1 12.76 4.65c.86.49 1.69 1 2.47 1.55-9.15 1.87-18.41 4.26-26.1 6.61C65 19.14 50.76 24.56 38.24 31.69c-7.59 4.33-18.57 13.74-13.9 23 3.78 6.78 16.5 9.6 19.05 10.33l17 4.39"
        fill={primaryFill}
      />
      <path
        d="M47.1 45.25c.23 4.81 7.64 7.13 13.76 8.76 7.91 2.31 14.59 4.22 22.38 6.83 5.84 1.95 13.21 5.65 13.46 13.74.41 13.32-24.32 26-30.53 29.25-6.42 3.42-25.82 12.79-30 14.66A157.08 157.08 0 0 0 60 120c48 0 60-12 60-60 0-19.77-2-33.42-7.79-42.65-8.64 1.4-65.8 13.72-65.11 27.9Z"
        fill={primaryFill}
      />
    </Svg>
  )
}
