import { type ColorName, cssvarColor } from 'design-tokens'
import { type AnchorHTMLAttributes, type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { shouldForwardProp } from './utils/shouldForwardProp'

export type LinkProps = PropsWithChildren<{
  colorName?: ColorName
  colorNameHover?: ColorName
}> &
  AnchorHTMLAttributes<HTMLAnchorElement>

export const Link = styled.a.withConfig({
  displayName: 'Link',
  shouldForwardProp,
})<LinkProps>`
  color: ${({ colorName = 'primary-50' }) => cssvarColor(colorName)};

  &:hover,
  &:focus {
    color: ${({ colorNameHover = 'primary-80' }) => cssvarColor(colorNameHover)};
  }
`
