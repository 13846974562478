import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisRingingBell = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} viewBox="0 0 24 24" width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.219 8.651a7.245 7.245 0 0 0-14.436 0l-.228 2.655a7.6 7.6 0 0 1-1.104 3.342l-.7 1.132c-.7 1.137-.318 2.312 1.006 2.488 1.515.202 4.043.382 8.244.382 4.2 0 6.73-.18 8.243-.381 1.325-.177 1.708-1.351 1.006-2.488l-.7-1.133a7.6 7.6 0 0 1-1.104-3.342L19.22 8.65Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.2"
    />
    <path
      d="M19.219 8.651a7.245 7.245 0 0 0-14.436 0l-.228 2.655a7.6 7.6 0 0 1-1.104 3.342l-.7 1.132c-.7 1.137-.318 2.312 1.006 2.488 1.515.202 4.043.382 8.244.382 4.2 0 6.729-.18 8.243-.381 1.325-.177 1.708-1.351 1.006-2.488l-.7-1.133a7.6 7.6 0 0 1-1.104-3.342L19.22 8.65ZM9.467 21.004a3.8 3.8 0 0 0 5.069 0"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
