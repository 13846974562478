import { Flex, type FlexProps } from 'cdk'
import {
  BORDER_WIDTH_05,
  type BorderRadiusName,
  COLOR_DARKER,
  COLOR_ERROR,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_70,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY,
  COLOR_PRIMARY_10,
  COLOR_RED_20,
  COLOR_RED_80,
  cssvar,
  cssvarBorderRadius,
  cssvarFontSize,
  cssvarLetterSpacing,
  cssvarLineHeight,
  cssvarSpacing,
  FONT_FAMILY_DEGULAR_TEXT,
  FONT_WEIGHT_400,
  type FontSizeName,
  type LetterSpacingName,
  type LineHeightName,
  SPACING_3XS,
  type SpacingName,
  TIME_300,
} from 'design-tokens'
import { forwardRef, type PropsWithChildren, type ReactNode, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { Radio, type RadioProps } from './Radio'
import { Hint } from '.'

type SizeName = 'xs' | 'sm' | 'md' | 'lg'

export type RadioButtonProps = RadioProps &
  PropsWithChildren<{
    sizeName?: SizeName
    hint?: ReactNode

    /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
    name?: string
  }>

type RadioButtonStyle = {
  borderRadius: BorderRadiusName
  fontSize: FontSizeName
  gap: SpacingName
  letterSpacing: LetterSpacingName
  lineHeight: LineHeightName
  px: SpacingName
  py: SpacingName
}

export const radioButtonSizesStyles: Record<SizeName, RadioButtonStyle> = {
  xs: { borderRadius: '2xs', fontSize: '14', gap: '2xs', letterSpacing: '040', lineHeight: '16', px: '2xs', py: 'xs' },
  sm: { borderRadius: 'xs', fontSize: '16', gap: '2xs', letterSpacing: '020', lineHeight: '24', px: 'xs', py: 'xs' },
  md: { borderRadius: 'xs', fontSize: '18', gap: 'xs', letterSpacing: '020', lineHeight: '24', px: 'sm', py: 'sm' },
  lg: { borderRadius: 'sm', fontSize: '20', gap: 'xs', letterSpacing: '020', lineHeight: '24', px: 'sm', py: 'md' },
}

const RadioButtonContainer = styled(Flex).withConfig({ displayName: 'RadioButtonContainer' })(
  () => css`
    --srns-radio-button-box-shadow: none;
    --srns-radio-button-color-background: ${COLOR_LIGHTER};
    --srns-radio-button-color-border: ${COLOR_NEUTRAL_40};
    --srns-radio-button-color-box-shadow: ${COLOR_LIGHTER};
    --srns-radio-button-color-hint: ${COLOR_NEUTRAL_80};
    --srns-radio-button-color-input: ${COLOR_DARKER};

    &:has(:checked) {
      --srns-radio-button-color-background: ${COLOR_PRIMARY_10};
      --srns-radio-button-color-border: ${COLOR_PRIMARY};
      --srns-radio-button-color-hint: ${COLOR_NEUTRAL_80};
      --srns-radio-button-color-input: ${COLOR_DARKER};
    }

    &:has(:disabled) {
      --srns-radio-button-color-background: ${COLOR_NEUTRAL_20};
      --srns-radio-button-color-border: ${COLOR_NEUTRAL_40};
      --srns-radio-button-color-input: ${COLOR_NEUTRAL_70};
    }

    &:has(:user-invalid) {
      --srns-radio-button-color-border: ${COLOR_ERROR};
      --srns-radio-button-color-hint: ${COLOR_ERROR};
      --srns-radio-button-color-input: ${COLOR_ERROR};
    }

    &:has(:checked:user-invalid) {
      --srns-radio-button-color-background: ${COLOR_RED_20};
      --srns-radio-button-color-input: ${COLOR_RED_80};
    }

    &:has(:enabled:hover) {
      --srns-radio-button-box-shadow: 0 0 0 2px rgb(from ${cssvar('radio-button-color-box-shadow')} r g b / 24%);
      --srns-radio-button-color-border: ${COLOR_PRIMARY};
      --srns-radio-button-color-input: ${COLOR_PRIMARY};

      &:has(:checked) {
        --srns-radio-button-color-background: ${COLOR_LIGHTER};
        --srns-radio-button-color-box-shadow: ${COLOR_PRIMARY};
      }

      &:has(:user-invalid) {
        --srns-radio-button-color-background: ${COLOR_LIGHTER};
        --srns-radio-button-color-border: ${COLOR_ERROR};
        --srns-radio-button-color-box-shadow: ${COLOR_ERROR};
        --srns-radio-button-color-input: ${COLOR_ERROR};
      }
    }

    ${Hint} {
      color: ${cssvar('radio-button-color-hint')};
    }
  `,
)

type RadioButtonLabelProps = FlexProps & {
  $size: SizeName
}

const RadioButtonLabel = styled(Flex).withConfig({ displayName: 'RadioButtonLabel' })<RadioButtonLabelProps>`
  ${({ $size }) => {
    const { fontSize, letterSpacing, lineHeight } = radioButtonSizesStyles[$size]

    return css`
      font-weight: ${FONT_WEIGHT_400};
      font-size: ${cssvarFontSize(fontSize)};
      font-family: ${FONT_FAMILY_DEGULAR_TEXT};
      line-height: ${cssvarLineHeight(lineHeight)};
      letter-spacing: calc(${cssvarLetterSpacing(letterSpacing)} * -1);
    `
  }}
  border-color: ${cssvar('radio-button-color-border')};
  background-color: ${cssvar('radio-button-color-background')};
  color: ${cssvar('radio-button-color-input')};
  box-shadow: ${cssvar('radio-button-box-shadow')};
  transition:
    color ${TIME_300} ease-out,
    background-color ${TIME_300} ease-out,
    border-color ${TIME_300} ease-out,
    box-shadow ${TIME_300} ease-out;

  &:has(:disabled) {
    cursor: not-allowed;
  }

  &:has(:enabled) {
    cursor: pointer;
  }
`

export const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ children, hint, sizeName = 'sm', ...radioProps }, ref) => {
    const identifier = `${radioProps.name}-${radioProps.value}`
    const hintId = hint ? `${identifier}-hint` : undefined
    const labelId = `${identifier}-label`

    const { borderRadius, gap, px, py } = useMemo(() => {
      const { borderRadius, gap, px, py } = radioButtonSizesStyles[sizeName]

      return {
        gap: cssvarSpacing(gap),
        borderRadius: cssvarBorderRadius(borderRadius),
        px: cssvarSpacing(px),
        py: cssvarSpacing(py),
      }
    }, [sizeName])

    return (
      <RadioButtonContainer $gap={SPACING_3XS}>
        <RadioButtonLabel
          $align="center"
          $borderRadius={borderRadius}
          $borderSize={BORDER_WIDTH_05}
          $direction="row"
          $gap={gap}
          $px={px}
          $py={py}
          $size={sizeName}
          as="label"
          htmlFor={identifier}
          id={labelId}
        >
          <Radio {...radioProps} ref={ref} aria-labelledby={hintId} id={identifier} />
          {children}
        </RadioButtonLabel>
        {hint && (
          <Flex $px={px}>
            <Hint id={hintId}>{hint}</Hint>
          </Flex>
        )}
      </RadioButtonContainer>
    )
  },
)
