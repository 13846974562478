import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const Intercom = ({ color, height, width }: Props) => (
  <svg
    data-test-id="icon-intercom"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1245 22C21.1245 22 18.0736 20.8407 15.6053 19.912H5.22488C3.99587 19.912 3 18.8916 3 17.6336V4.27784C3 3.02041 3.99587 2 5.22488 2H18.8983C20.1274 2 21.1239 3.02041 21.1239 4.27784V15.7807L21.1245 15.78V22ZM18.4456 14.511C18.2325 14.2565 17.8573 14.2275 17.6093 14.4451C17.5899 14.4619 15.6576 16.1217 12.061 16.1217C8.5102 16.1217 6.54818 14.4735 6.51266 14.4438C6.26401 14.2268 5.89073 14.2572 5.6776 14.511C5.46383 14.7661 5.49354 15.1491 5.74219 15.368C5.83454 15.4481 8.03229 17.3365 12.061 17.3365C16.0916 17.3365 18.29 15.4481 18.381 15.368C18.6297 15.1491 18.6594 14.7661 18.4456 14.511Z"
      fill={color}
    />
  </svg>
)
