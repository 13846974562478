import { type ReactNode } from 'react'
import styled from 'styled-components'
import { Flex } from '../../Flex'
import { NonSelectable } from '../../NonSelectable'

type Props = {
  children: ReactNode
}

const Paper = styled(Flex)`
  width: 100%;
`

/** @deprecated */
export const CalendarPaper = ({ children }: Props) => (
  <NonSelectable>
    <Paper background="white" borderRadius={24} px={16} py={12}>
      {children}
    </Paper>
  </NonSelectable>
)
