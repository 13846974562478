import { type ComponentProps } from 'react'
import styled from 'styled-components'
import { Flex } from './Flex'
import { MaxWidth640px } from './MaxWidth'
import { Toast } from './Toast'

const fixedProps = { justify: 'center', px: 24 } as const

const TopBar = styled(MaxWidth640px).attrs((props) => ({ ...props, ...fixedProps }))`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
`

TopBar.displayName = 'TopBar'

type ToastProps = Omit<ComponentProps<typeof Toast>, 'children'>

type Props = {
  items: (ToastProps & { label: string })[]
}

/** @deprecated */
export const Toasts = ({ items }: Props) => (
  <TopBar>
    {items.map(({ label, ...props }) => (
      <Flex key={props.id} pt={24}>
        <Toast {...props}>{label}</Toast>
      </Flex>
    ))}
  </TopBar>
)
