import { type ComponentProps, memo } from 'react'
import { Markdown } from '~/components/Markdown'
import { useTranslation } from '~/i18n/hooks/useTranslation'
import { type TranslationId, type TranslationValues } from '~/i18n/types'

type Props = {
  id: TranslationId
  values?: TranslationValues
} & Partial<
  Pick<
    ComponentProps<typeof Markdown>,
    'textAlign' | 'colorName' | 'colorNameBold' | 'fontWeight' | 'kind' | 'lgPt' | 'mdPt' | 'pt' | 'target'
  >
>

export const TranslationMarkdown = memo(
  ({
    textAlign,
    colorName = 'black',
    colorNameBold,
    fontWeight,
    id,
    kind = 'paragraph',
    lgPt,
    mdPt,
    pt,
    target = '_blank',
    values,
  }: Props) => {
    const translation = useTranslation(id, values)

    return (
      <Markdown
        colorName={colorName}
        colorNameBold={colorNameBold}
        fontWeight={fontWeight}
        kind={kind}
        lgPt={lgPt}
        mdPt={mdPt}
        pt={pt}
        target={target}
        textAlign={textAlign}
      >
        {translation}
      </Markdown>
    )
  },
)

TranslationMarkdown.displayName = 'TranslationMarkdown'
