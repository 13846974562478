import { Flex } from '../../Flex'
import { useCalendar } from '../hooks/useCalendar'
import { type Day } from '../types'
import { CalendarDay } from './CalendarDay'

type Props = {
  onSelect: (day: Day) => void
}

/** @deprecated */
export function CalendarDays({ onSelect }: Props) {
  const { days } = useCalendar()

  return (
    <Flex direction="row" grow={1} shrink={1} wrap="wrap">
      {days.map((day) => (
        <CalendarDay key={day.date.getTime()} day={day} onSelect={onSelect} />
      ))}
    </Flex>
  )
}
