import { type ColorName, toColorName, toColorNameHover } from '@serenis-health/tokens'
import { noop } from 'functions'
import { type ComponentPropsWithRef } from 'react'
import styled from 'styled-components'
import { Flex, type FlexProps } from './Flex'

type Props = {
  colorName?: ColorName
  colorNameHover?: ColorName
  disabled?: boolean
  autoWidth?: boolean
  type?: 'button' | 'submit'
} & ComponentPropsWithRef<'button'> &
  Partial<Pick<ComponentPropsWithRef<'button'>, 'onClick'>>

const defaultProps: FlexProps = {
  align: 'center',
  background: 'transparent',
  grow: 1,
  justify: 'center',
  shrink: 1,
}

/** @deprecated Use the `cdk` one */
export const Pressable = styled(Flex).attrs<Props>(({ colorName = 'black', onClick, type = 'button', ...props }) => ({
  ...defaultProps,
  ...props,
  as: 'button',
  colorName,
  onClick: onClick ?? noop,
  role: 'button',
  type,
}))<Props>`
  /* stylelint-disable no-descending-specificity */

  margin: 0;
  padding: 0;
  border: none;
  color: ${({ colorName }) => toColorName(colorName)};
  outline: 0;
  font-family: inherit;
  cursor: pointer;
  appearance: none;
  ${({ autoWidth = false }) => !autoWidth && 'width: 100%;'}

  &:active,
  &:hover {
    &:not([disabled]) {
      color: ${({ colorName, colorNameHover }) =>
        colorNameHover ? toColorName(colorNameHover) : toColorNameHover(colorName)};
    }
  }

  &:disabled,
  &[disabled] {
    cursor: auto;
  }

  &:active {
    &:not([disabled]) {
      opacity: 0.8;
    }
  }

  > * {
    line-height: unset;
  }
`

Pressable.displayName = 'Pressable'
