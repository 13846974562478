import { colors, typographyFlavours } from '@serenis-health/tokens'
import { type ChangeEvent, forwardRef, type Ref, useCallback } from 'react'
import styled from 'styled-components'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { type TextAreaProps, type TextAreaStyleProps } from './types'
import { getBackgroundColorFromProps } from './utils/getBackgroundColorFromProps'
import { getBorderColorFromProps } from './utils/getBorderColorFromProps'
import { getColorFromProps } from './utils/getColorFromProps'
import { getPlaceholderColorFromProps } from './utils/getPlaceholderColorFromProps'

const Wrapper = styled(Flex)`
  width: 100%;
`

const StyledTextArea = styled.textarea<TextAreaStyleProps>`
  flex-grow: 1;
  flex-shrink: 1;
  width: 100%;
  padding: 16px;
  border-color: ${getBorderColorFromProps(false)};
  background-color: ${getBackgroundColorFromProps};
  color: ${getColorFromProps};
  box-shadow: inset 0 0 0 2px ${colors.transparent};
  outline: none;
  font-size: ${typographyFlavours.paragraph.fontSize};
  line-height: ${typographyFlavours.paragraph.lineHeight};
  opacity: 1;
  resize: none;
  transition:
    background-color 100ms ease-in-out,
    border-color 100ms ease-in-out,
    box-shadow 100ms ease-in-out;
  -webkit-text-fill-color: ${getColorFromProps};
  appearance: none;

  ${({ minHeight }) => (minHeight ? `min-height: ${minHeight};` : '')}

  &::placeholder {
    color: ${getPlaceholderColorFromProps};
    -webkit-text-fill-color: ${getPlaceholderColorFromProps};
  }

  &:focus {
    border-color: ${colors.transparent};
    box-shadow: inset 0 0 0 1px ${getBorderColorFromProps(true)};
  }
`

/** @deprecated */
export const TextArea = forwardRef(
  (
    {
      defaultValue,
      disabled = false,
      errorMessage,
      helperText,
      id,
      invalid = false,
      max = 1000,
      minHeight,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder = '',
      required = false,
      value,
    }: TextAreaProps,
    ref: Ref<HTMLTextAreaElement>,
  ) => {
    const inputId = id || name

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        if (onChange) {
          onChange(event.target.value)
        }
      },
      [onChange],
    )

    return (
      <Wrapper>
        <StyledTextArea
          ref={ref}
          defaultValue={defaultValue}
          disabled={disabled}
          id={inputId}
          invalid={invalid}
          maxLength={max}
          minHeight={minHeight}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          required={required}
          value={value}
        />

        {errorMessage && invalid && !disabled && (
          <Flex pt={8}>
            <Text colorName="coral10" kind="label">
              {errorMessage}
            </Text>
          </Flex>
        )}

        {helperText && (
          <Flex pt={8}>
            <Text colorName="grey10" kind="label">
              {helperText}
            </Text>
          </Flex>
        )}
      </Wrapper>
    )
  },
)
