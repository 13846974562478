import { assertNever } from 'functions'
import { type ReactNode, useMemo } from 'react'
import styled from 'styled-components'
import { Flex, type FlexProps } from './Flex'
import { MaxWidth1024px, MaxWidth1260px, MaxWidth400px, MaxWidth640px, MaxWidth840px } from './MaxWidth'

const defaultProps = {
  py: 32,
  px: 16,
} as const

const PageLayoutWrapper = styled(Flex).attrs<FlexProps>((props) => ({ ...defaultProps, ...props }))`
  /* stylelint-disable-next-line no-empty-source */
`

PageLayoutWrapper.displayName = 'PageLayoutWrapper'

type Props = {
  children: ReactNode
  maxWidth: '400px' | '640px' | '840px' | '1024px' | '1260px'
} & Partial<FlexProps>

/** @deprecated */
export const PageLayout = ({ children, maxWidth, ...flexProps }: Props) => {
  const MaxWidth = useMemo(() => {
    switch (maxWidth) {
      case '400px':
        return MaxWidth400px
      case '640px':
        return MaxWidth640px
      case '840px':
        return MaxWidth840px
      case '1024px':
        return MaxWidth1024px
      case '1260px':
        return MaxWidth1260px
      default:
        return assertNever(maxWidth)
    }
  }, [maxWidth])

  return (
    <PageLayoutWrapper {...flexProps}>
      <MaxWidth grow={1} shrink={1}>
        {children}
      </MaxWidth>
    </PageLayoutWrapper>
  )
}
