import { type MemoIconElementProps } from './Icon'

type Props = MemoIconElementProps

export const SerenisContactPhonebook = ({ color, height, width }: Props) => (
  <svg fill="none" height={height} width={width} xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.54951 2.26437c1.39602-.11922 3.55209-.2394 6.57779-.2394 3.0258 0 5.1812.11982 6.5777.23999 1.2118.10355 2.1261 1.0431 2.2154 2.25625.1131 1.5333.2318 3.9805.2318 7.47889 0 3.4984-.1187 5.9451-.2318 7.4793-.0888 1.2122-1.0036 2.1527-2.2154 2.2563-1.396.1192-3.5519.2393-6.5777.2393-3.0257 0-5.1813-.1198-6.5778-.2399-1.21172-.1036-2.12611-1.0431-2.21541-2.2563-.11305-1.5333-.2318-3.9805-.2318-7.4789 0-3.49833.11875-5.94503.2318-7.47928.08883-1.2122 1.00369-2.1527 2.21542-2.25625Z"
      stroke={color}
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <path
      d="M6 6H2M6 10H2M6 14H2M6 18H2M9.11549 12.249c.04449.365.46348.6443 1.01101.674.6918.0375 1.7955.077 3.3735.077 1.578 0 2.6817-.0395 3.3735-.077.5475-.0297.9665-.309 1.011-.674C17.9407 11.7878 18 11.052 18 10c0-.60043-.0193-1.09785-.0463-1.5-.0204-.30245-.0451-.55101-.0692-.74897-.0445-.36505-.4635-.64438-1.011-.67403C16.1817 7.03953 15.078 7 13.5 7c-1.578 0-2.6817.03952-3.3735.07699-.54753.02966-.96652.30899-1.01101.67403-.02412.19797-.04882.44653-.06915.74898C9.01931 8.90215 9 9.39957 9 10c0 1.052.05929 1.7878.11549 2.249Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
)
