import { createContext, useContext } from 'react'
import { type Calendar, type CalendarValue } from '../types'
import { getCalendarDays } from '../utils/getCalendarDays'

const CalendarContext = createContext<Calendar | null>(null)

/** @deprecated */
export const CalendarProvider = CalendarContext.Provider

/** @deprecated */
export const useCalendar = (): CalendarValue => {
  const context = useContext(CalendarContext)

  if (!context) {
    throw new Error('You must wrap the component using useCalendar() in a CalendarProvider!')
  }

  const { date, disabledBeforeDate, events, selectedDay, sundaysEnabled } = context

  return {
    date,
    days: getCalendarDays({ date, disabledBeforeDate, events, selectedDay, sundaysEnabled }),
    disabledBeforeDate,
    events,
  }
}
