import { assertNever } from 'functions'
import { type PropsWithChildren, useMemo } from 'react'
import {
  Flex,
  type FlexProps,
  MaxWidth1024px,
  MaxWidth1260px,
  MaxWidth400px,
  MaxWidth640px,
  MaxWidth840px,
} from './Flex'

type PageLayoutProps = PropsWithChildren<{
  maxWidth: '400px' | '640px' | '840px' | '1024px' | '1260px'
}> &
  FlexProps

export const PageLayout = ({ children, maxWidth, ...flexProps }: PageLayoutProps) => {
  const MaxWidth = useMemo(() => {
    switch (maxWidth) {
      case '400px':
        return MaxWidth400px
      case '640px':
        return MaxWidth640px
      case '840px':
        return MaxWidth840px
      case '1024px':
        return MaxWidth1024px
      case '1260px':
        return MaxWidth1260px
      default:
        return assertNever(maxWidth)
    }
  }, [maxWidth])

  return (
    <Flex $px={16} $py={32} {...flexProps}>
      <MaxWidth $grow={1} $shrink={1}>
        {children}
      </MaxWidth>
    </Flex>
  )
}
