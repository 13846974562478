import { isBefore, isSunday } from 'date-fns/fp'
import { type Day } from '../types'

type Param = {
  disabledBeforeDate: Date
  sundaysEnabled: boolean
}

/** @deprecated */
export const isCalendarDayDisabled =
  ({ disabledBeforeDate, sundaysEnabled }: Param) =>
  ({ belongsToCurrentMonth, date }: Pick<Day, 'belongsToCurrentMonth' | 'date'>) => {
    if (!belongsToCurrentMonth) {
      return true
    }

    if (isBefore(disabledBeforeDate, date)) {
      return true
    }

    if (sundaysEnabled) {
      return false
    }

    return isSunday(date)
  }
