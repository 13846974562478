import { type TextAlign } from '@serenis-health/tokens'
import { noop } from 'functions'
import { forwardRef, type Ref } from 'react'
import styled from 'styled-components'
import { useInput } from '../../hooks/useInput'
import { type AutoComplete } from '../../types/_index'
import { Flex } from '../Flex'
import { Text } from '../Text'
import { Input } from './components/Input'

type Props = {
  autoComplete?: AutoComplete
  disabled?: boolean
  errorMessage?: string
  helperText?: string
  inputMode?: 'decimal' | 'email' | 'numeric' | 'search' | 'tel' | 'text' | 'url' | 'none'
  invalid?: boolean
  label?: string
  name: string
  onBlur?: () => void
  onChange?: (value: string) => void
  onFocus?: () => void
  placeholder?: string
  size?: 'small' | 'medium'
  readOnly?: boolean
  textAlign?: TextAlign
  type: 'email' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url' | 'date'
  value: string
  warning?: boolean
}

type Attrs = { size: Props['size'] }

const TextFieldNewContainer = styled(Flex).attrs<Attrs>(({ size }) => ({
  py: size === 'medium' ? 8 : 4,
}))<Attrs>`
  min-height: ${({ size }) => (size === 'medium' ? '60px' : '48px')};
`

/** @deprecated */
export const TextFieldNew = forwardRef(
  (
    {
      autoComplete,
      disabled = false,
      errorMessage = '',
      helperText = '',
      inputMode,
      invalid = false,
      label,
      name,
      onBlur = noop,
      onChange = noop,
      onFocus = noop,
      placeholder,
      readOnly = false,
      size = 'medium',
      textAlign = 'left',
      type,
      value,
      warning = false,
    }: Props,
    ref: Ref<HTMLInputElement>,
  ) => {
    const { error, inputColorName, handleBlur, handleChange, handleFocus, labelColorName, borderColorName } = useInput({
      disabled,
      invalid,
      onBlur,
      onChange,
      onFocus,
      readOnly,
      value,
      warning,
    })

    return (
      <TextFieldNewContainer
        background="white"
        borderColor={borderColorName}
        borderRadius={8}
        borderSize={1}
        justify="center"
        px={16}
        size={size}
      >
        {label && (
          <Text align={textAlign} as="label" colorName={labelColorName} htmlFor={name} kind="label">
            {label}
          </Text>
        )}

        <Input
          ref={ref}
          autoComplete={autoComplete}
          colorName={inputColorName}
          disabled={disabled}
          id={name}
          inputMode={inputMode}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          placeholder={placeholder}
          readOnly={readOnly}
          textAlign={textAlign}
          type={type}
          value={value}
        />

        {errorMessage && error && (
          <Flex data-test-id="form-text-field-error" pt={8}>
            <Text align={textAlign} colorName="coral10" kind="caption">
              {errorMessage}
            </Text>
          </Flex>
        )}

        {helperText && (
          <Flex pt={8}>
            <Text align={textAlign} colorName="grey10" kind="caption">
              {helperText}
            </Text>
          </Flex>
        )}
      </TextFieldNewContainer>
    )
  },
)
