import {
  BORDER_RADIUS_2XS,
  BORDER_WIDTH_05,
  COLOR_DARKER,
  COLOR_ERROR,
  COLOR_LIGHTER,
  COLOR_NEUTRAL_20,
  COLOR_NEUTRAL_40,
  COLOR_NEUTRAL_50,
  COLOR_NEUTRAL_80,
  COLOR_PRIMARY,
  cssvar,
  cssvarFontSize,
  cssvarLetterSpacing,
  cssvarSpacing,
  type FontSizeName,
  type LetterSpacingName,
  LINE_HEIGHT_24,
  SPACING_SM,
  type SpacingName,
  TIME_300,
} from 'design-tokens'
import { type ChangeEvent, type FormEvent, forwardRef, type InputHTMLAttributes, useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

type SizeName = 'sm' | 'md' | 'lg'

export const defaultTextInputSize: SizeName = 'md'

type TextInputSizeStyle = {
  fontSize: FontSizeName
  letterSpacing: LetterSpacingName
  py: SpacingName
}

const inputTextSizesStyles: Record<SizeName, TextInputSizeStyle> = {
  sm: { py: 'xs', fontSize: '16', letterSpacing: '020' },
  md: { py: 'sm', fontSize: '18', letterSpacing: '040' },
  lg: { py: 'md', fontSize: '20', letterSpacing: '056' },
}

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  /** Use `name` for `aria-labelledby` @see https://www.w3.org/WAI/tutorials/forms/instructions/ */
  name?: string
  $sizeName?: SizeName
}

export const Input = styled.input.withConfig({ displayName: 'TextInput' })<TextInputProps>`
  --srns-text-input-box-shadow: none;
  --srns-text-input-color-background: ${COLOR_LIGHTER};
  --srns-text-input-color-border: ${COLOR_NEUTRAL_40};
  --srns-text-input-color-box-shadow: ${COLOR_LIGHTER};
  --srns-text-input-color-input: ${COLOR_DARKER};
  --srns-text-input-color-placeholder: ${COLOR_NEUTRAL_80};

  all: unset;
  ${({ $sizeName = defaultTextInputSize }) => {
    const { fontSize, letterSpacing, py } = inputTextSizesStyles[$sizeName]
    return css`
      padding-block: ${cssvarSpacing(py)};
      font-size: ${cssvarFontSize(fontSize)};
      letter-spacing: calc(${cssvarLetterSpacing(letterSpacing)} * -1);
    `
  }}
  padding-inline: ${SPACING_SM};
  border: ${BORDER_WIDTH_05} solid ${cssvar('text-input-color-border')};
  border-radius: ${BORDER_RADIUS_2XS};
  background-color: ${cssvar('text-input-color-background')};
  color: ${cssvar('text-input-color-input')};
  box-shadow: ${cssvar('text-input-box-shadow')};
  outline: revert;
  outline-offset: revert;
  line-height: ${LINE_HEIGHT_24};
  transition:
    background-color ${TIME_300} ease-out,
    border-color ${TIME_300} ease-out,
    box-shadow ${TIME_300} ease-out,
    color ${TIME_300} ease-out;

  &::placeholder {
    color: ${cssvar('text-input-color-placeholder')};
  }

  &:focus-visible {
    --srns-text-input-color-border: ${COLOR_PRIMARY};
    --srns-text-input-box-shadow: 0 0 0 2px rgb(from ${cssvar('text-input-color-box-shadow')} r g b / 24%);
    --srns-text-input-color-box-shadow: ${COLOR_PRIMARY};
  }

  &:read-only {
    --srns-text-input-color-border: ${COLOR_DARKER};
  }

  &:user-invalid {
    --srns-text-input-color-border: ${COLOR_ERROR};
    --srns-text-input-color-box-shadow: ${COLOR_ERROR};
  }

  &:disabled {
    --srns-text-input-color-background: ${COLOR_NEUTRAL_20};
    --srns-text-input-color-input: ${COLOR_NEUTRAL_50};
    --srns-text-input-color-border: ${COLOR_NEUTRAL_40};

    cursor: not-allowed;

    &::placeholder {
      --srns-text-input-color-placeholder: ${COLOR_NEUTRAL_50};
    }
  }

  &:enabled:valid:hover {
    --srns-text-input-color-border: ${COLOR_PRIMARY};

    &::placeholder {
      --srns-text-input-color-placeholder: ${COLOR_PRIMARY};
    }
  }
`

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({ onChange, onInvalid, ...props }, ref) => {
  const [isInvalid, setIsInvalid] = useState(false)

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsInvalid(!event.target.checkValidity())

      if (onChange) {
        onChange(event)
      }
    },
    [onChange],
  )

  const handleOnInvalid = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      setIsInvalid(true)

      if (onInvalid) {
        onInvalid(event)
      }
    },
    [onInvalid],
  )

  return (
    <Input
      ref={ref}
      aria-disabled={props.disabled}
      aria-invalid={isInvalid}
      {...props}
      onChange={handleOnChange}
      onInvalid={handleOnInvalid}
    />
  )
})
