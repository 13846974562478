import { ApolloClient, ApolloLink, type DefaultOptions, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Auth } from '@aws-amplify/auth'
import queryString from 'query-string'
import { getEnv } from '~/utils/getEnv'
import { getSessionStorageQueryParams } from '~/utils/getSessionStorageQueryParams'
import { upsertAnonymousFingerprint } from '~/utils/upsertAnonymousFingerprint'
import { castGraphqlDateFields } from './castGraphqlDateFields'

const dateCastLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => castGraphqlDateFields(response)),
)

const cache = new InMemoryCache()

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'all',
  },
}

const httpLink = new HttpLink({
  uri: getEnv('API_GATEWAY_URL'),
  credentials: 'same-origin',
})

const authLink = setContext(async (_, { headers }) => {
  const queryParams = getSessionStorageQueryParams()

  const xSerenisHeaders = {
    'x-srns-analytics': queryParams ? queryString.stringify(queryParams) : '',
    'x-srns-fingerprint-id': upsertAnonymousFingerprint(),
  }

  try {
    const session = await Auth.currentSession()
    const idToken = await session.getIdToken().getJwtToken()

    return {
      headers: {
        ...headers,
        ...xSerenisHeaders,
        authorization: `Bearer ${idToken}`,
      },
    }
  } catch (error) {
    return {
      headers: {
        ...headers,
        ...xSerenisHeaders,
        authorization: '',
      },
    }
  }
})

export const Apollo = new ApolloClient({
  cache,
  defaultOptions,
  link: dateCastLink.concat(authLink).concat(httpLink),
  ssrMode: false,
})
