import { css, type CSSProperties } from 'styled-components'
import { type StyledProps } from '../utils/props'
import { toValueOrPX } from '../utils/toValueOrPX'

type BaseSizeProps = {
  maxHeight?: CSSProperties['maxHeight']
  maxWidth?: CSSProperties['maxWidth']
  minHeight?: CSSProperties['minHeight']
  minWidth?: CSSProperties['minWidth']
}

export type SizeProps = StyledProps<BaseSizeProps>

export const generateSizeStyles = ({ maxHeight, minHeight, maxWidth, minWidth }: BaseSizeProps) => css`
  min-width: ${toValueOrPX(minWidth)};
  max-width: ${toValueOrPX(maxWidth)};
  min-height: ${toValueOrPX(minHeight)};
  max-height: ${toValueOrPX(maxHeight)};

  ${!!maxWidth &&
  css`
    width: 100%;
    margin: 0 auto;
  `}
`
