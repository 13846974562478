import { SPACING_XS } from 'design-tokens'
import { type AllHTMLAttributes, type ComponentProps, type PropsWithChildren } from 'react'
import styled from 'styled-components'
import { Card } from './Card'
import { shouldForwardProp } from './utils/shouldForwardProp'

const TileContainer = styled(Card).withConfig({ displayName: 'Tile', shouldForwardProp }).attrs({ size: 'sm' })`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_XS};
`

const TileChildrenContainer = styled.div`
  flex: 1;
`

export type TileProps = PropsWithChildren<{
  endChildren?: PropsWithChildren['children']
  startChildren?: PropsWithChildren['children']
}> &
  ComponentProps<typeof TileContainer> &
  AllHTMLAttributes<HTMLElement>

export const Tile = ({ children, endChildren, startChildren, ...htmlProps }: TileProps) => (
  <TileContainer {...htmlProps}>
    {startChildren}
    <TileChildrenContainer>{children}</TileChildrenContainer>
    {endChildren}
  </TileContainer>
)
