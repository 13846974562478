import styled from 'styled-components'
import { Flex, type FlexProps } from './Flex'

type Props = Omit<FlexProps, 'grow' | 'shrink'>

const defaultProps: Partial<FlexProps> = {
  pb: 16,
}

const fixedProps: Pick<FlexProps, 'grow' | 'shrink'> = {
  grow: 1,
  shrink: 1,
}

/** @deprecated Use the `cdk` one */
export const OverflowAuto = styled(Flex).attrs<Props>((props) => ({
  ...defaultProps,
  ...props,
  ...fixedProps,
}))`
  overflow: auto;
  scroll-behavior: smooth;
`

OverflowAuto.displayName = 'OverflowAuto'
