import { type ColorName, toColorName } from '@serenis-health/tokens'
import styled, { keyframes } from 'styled-components'
import { shouldForwardProp } from '../utils/shouldForwardProp'

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

type Props = {
  colorName: ColorName
  size: 16 | 20 | 24 | 32
}

const SpinnerBorder = styled.div.withConfig({
  displayName: 'SpinnerBorder',
  shouldForwardProp,
})<Props>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: 2px solid ${({ colorName }) => toColorName(colorName)};
  border-right-color: transparent;
  border-radius: 50%;
  animation: ${rotate} 0.75s linear infinite;
`

const ScreenReaderOnly = styled.span`
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  white-space: nowrap;
`

/** @deprecated */
export const Loader = ({ colorName, size }: Props) => (
  <SpinnerBorder colorName={colorName} role="status" size={size}>
    <ScreenReaderOnly>Loading...</ScreenReaderOnly>
  </SpinnerBorder>
)
